/* eslint-disable react/jsx-no-useless-fragment */
import type React from 'react';
import type { InventorizationResponse } from '../../../../../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import locale from '../../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from '../WrapperMenu';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiskVirtual({ data }: { data: InventorizationResponse }): React.ReactElement {
  return (
    <>
      {(data.type === 'linux' || data.type === 'windows') &&
        data.result.disk?.map((disk) => {
          if (disk.info && 'id' in disk.info && disk.info.id) {
            return null;
          }

          return (
            <WrapperMenu title={disk.info?.name}>
              <WrapperMenu title={locale[currentLocale].disks.titleInfo}>
                <table className="column">
                  <tbody>
                    <tr>
                      <td>
                        <span>{locale[currentLocale].disks.index}</span>
                      </td>
                      <td>{disk.info?.disk_index}</td>
                    </tr>
                    <tr>
                      <td>
                        <span>{locale[currentLocale].disks.size}</span>
                      </td>
                      <td>
                        {disk.info?.disk_size} {locale[currentLocale].sizeGb}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{locale[currentLocale].disks.partitions}</span>
                      </td>
                      {data.type === 'linux' &&
                      disk.info &&
                      'partitions' in disk.info &&
                      disk.info?.partitions &&
                      typeof disk.info?.partitions === 'string' ? (
                        <td>{disk.info?.partitions}</td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  </tbody>
                </table>
                <table className="column">
                  <tbody>
                    <tr>
                      <td>
                        <span>{locale[currentLocale].disks.identifier}</span>
                      </td>
                      {data.type === 'linux' &&
                      disk.info &&
                      'id' in disk.info &&
                      disk.info?.id &&
                      typeof disk.info?.id === 'string' ? (
                        <td>{disk.info?.id}</td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                    <tr>
                      <td>
                        <span>{locale[currentLocale].disks.description}</span>
                      </td>
                      {disk.info && 'description' in disk.info ? (
                        <td>{disk.info?.description}</td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </WrapperMenu>
            </WrapperMenu>
          );
        })}
    </>
  );
}
