/* eslint-disable no-nested-ternary */
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from './WrapperMenu';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function Cpu({ data }: { data: InventorizationResponse }): React.ReactElement {
  return (
    <WrapperMenu title={locale[currentLocale].cpu.title}>
      {(data.type === 'linux' || data.type === 'windows') &&
        data.result.cpu_info?.map((cpu) => (
          <WrapperMenu title={cpu?.model}>
            <table className="column">
              <tbody>
                <tr>
                  <td>{locale[currentLocale].cpu.model}</td>
                  <td>{cpu?.model}</td>
                </tr>
                <tr>
                  <td>{locale[currentLocale].cpu.socket_designation}</td>
                  <td>{cpu?.socket_designation}</td>
                </tr>
                <tr>
                  <td>{locale[currentLocale].cpu.manufacturer}</td>
                  <td>{cpu?.manufacturer}</td>
                </tr>
                <tr>
                  <td>{locale[currentLocale].cpu.processorType}</td>
                  <td>{cpu?.processor_type}</td>
                </tr>
              </tbody>
            </table>
            <table className="column">
              <tbody>
                <tr>
                  <td>{locale[currentLocale].cpu.numberOfCores}</td>
                  <td>{cpu?.number_of_cores}</td>
                </tr>
                <tr>
                  <td>{locale[currentLocale].cpu.logicalProcessors}</td>
                  <td>{cpu?.logical_processors}</td>
                </tr>
                <tr>
                  <td>{locale[currentLocale].cpu.currentClockSpeed}</td>
                  <td>{cpu?.current_clock_speed}</td>
                </tr>
                <tr>
                  <td>{locale[currentLocale].cpu.maxClockSpeed}</td>
                  <td>{cpu?.max_clock_speed}</td>
                </tr>
              </tbody>
            </table>
            <table className="column">
              <tbody>
                <tr>
                  <td>{locale[currentLocale].cpu.addressWidth}</td>
                  <td>{cpu?.address_width}</td>
                </tr>
                <tr>
                  <td>{locale[currentLocale].cpu.availability}</td>
                  <td>{cpu?.availability}</td>
                </tr>
                <tr>
                  <td>{locale[currentLocale].cpu.device_id}</td>
                  <td>{cpu?.number_of_cores}</td>
                </tr>
                <tr>
                  <td>{locale[currentLocale].cpu.handle}</td>
                  {data.type === 'linux' &&
                  cpu &&
                  'handle' in cpu &&
                  typeof cpu.handle === 'string' ? (
                    <td>{cpu.handle}</td>
                  ) : (
                    <td>-</td>
                  )}
                </tr>
              </tbody>
            </table>
          </WrapperMenu>
        ))}
    </WrapperMenu>
  );
}
