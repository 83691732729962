/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useState } from 'react';
import { loadMessages } from '@progress/kendo-react-intl';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';
import localeCommonTable from '../../../utils/i18n/commonLocales/table.json';
import { LoginForm } from './LoginForm';
import localeLogin from '../../../utils/i18n/loginLocale/loginForm.json';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { checkCaptcha, sendLogin, sendTfaCode } from '../../../services/auth';
import type { ResponseApiError } from '../../../utils/errors';
import type { FormDataValue } from '../../../utils/helpers/types';
import type { UserLoginResponse } from '../../../types/__generated/on-premise-solution/api/userLoginResponse.v1';
import type {
  UserActiveDirectoryLoginRequest,
  UserLdapLoginRequest,
  UserLocalLoginRequest,
  UserLoginRequest,
} from '../../../types/__generated/on-premise-solution/api/userLoginRequest.v1';
import styles from './Form.module.scss';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { AxiosAuthInterceptor } from '../../../lib/axios/hooks/useAuthAxios';
import type { User2FALoginRequest } from '../../../types/__generated/on-premise-solution/api/user2FALoginRequest.v1';
import type { User2FALoginResponse } from '../../../types/__generated/on-premise-solution/api/user2FALoginResponse.v1';
import type { CaptchaValidateResponse } from '../../../types/__generated/on-premise-solution/api/captchaValidateResponse.v1';
import type { CaptchaValidateRequest } from '../../../types/__generated/on-premise-solution/api/captchaValidateRequest.v1';
import { ChangePasswordForm } from './ChangePasswordForm';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeLogin;

loadMessages(localeCommonTable[currentLocale], currentLocale);

export function LoginContainer(): React.ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const {
    setAccessToken,
    type,
    setType,
    setEmail,
    setPassword,
    setUid,
    setUsername,
    setLogin,
    isTwoFactoredAuthPage,
    setTwoFactoredAuthPage,
    setLoginError,
    incIncorrectAttempt,
    captcha,
    setCaptcha,
    isExpiredPassword,
    setExpiredPassword,
  } = useAuthContext();

  const [formData, setFormData] = useState<FormDataValue | null>(null);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      if (e.selected === 0) {
        setType('local');
        setSelected(e.selected);
        setLogin('');
        setPassword('');
      }
      if (e.selected === 1) {
        setType('ldap');
        setSelected(e.selected);
        setUid('');
        setPassword('');
      }
      if (e.selected === 2) {
        setType('activedirectory');
        setSelected(e.selected);
        setUsername('');
        setPassword('');
      }
    },
    [setLogin, setPassword, setType, setUid, setUsername],
  );

  useEffect(() => {
    setType('local');
  }, [setType]);

  const history = useHistory();

  const mutation = useMutation<UserLoginResponse, ApiError, UserLoginRequest>(
    (payload) => sendLogin(payload),
    {
      onSuccess: (response) => {
        setLogin('');
        setCaptcha('');
        if ('is2FARequired' in response && response.is2FARequired) {
          setTwoFactoredAuthPage(true);
        } else {
          setAccessToken(response.accessToken);
          history.push('/lk/audit/tasks');
        }
      },
      onError: (err) => {
        if (err.code === '401') setLoginError(localeLogin[currentLocale].errors.invalidLogPass);
        if (
          err.code === '401' &&
          (err.type === 'CaptchaRequired' || err.type === 'UnauthorizedCaptcha')
        ) {
          setCaptcha(err.captcha);
        }
        // if (err.code === '403' && err.type === 'Forbidden')
        //   setLoginError(localeLogin[currentLocale].errors.noCaptcha);
        else if (err.code === '403' && err.type === 'PasswordExpired') {
          setExpiredPassword(true);
          // setAccessToken(err.passwordExpiredToken);
        }
      },
      onSettled: () => {
        // setLogin('');
        setEmail('');
        setPassword('');
        setUid('');
        setUsername('');
      },
    },
  );

  const tfaMutation = useMutation<User2FALoginResponse, ResponseApiError, User2FALoginRequest>(
    (payload) => sendTfaCode(payload),
    {
      onSuccess: (response) => {
        setLogin('');
        setAccessToken(response.accessToken);

        history.push('/lk/audit/tasks');
        setTwoFactoredAuthPage(false);
      },
      onError: (err) => {
        if (err.code === '403') {
          setLoginError(localeLogin[currentLocale].errors.incorrectCode);
          incIncorrectAttempt();
        }
        if (err.code === '500') {
          setTwoFactoredAuthPage(false);
          setLoginError(localeLogin[currentLocale].errors.jwtExpired);
        }
      },
      onSettled: () => {
        setEmail('');
        setPassword('');
        setUid('');
        setUsername('');
        setFormData(null);
      },
    },
  );

  const handleSubmitWithoutCaptcha = (dataItem: FormDataValue): void => {
    if (type === 'local') {
      mutation.mutateAsync({ ...dataItem, type } as unknown as UserLocalLoginRequest);
    }
    if (type === 'ldap') {
      mutation.mutateAsync({ ...dataItem, type } as unknown as UserLdapLoginRequest);
    }
    if (type === 'activedirectory') {
      mutation.mutateAsync({
        ...dataItem,
        type,
      } as unknown as UserActiveDirectoryLoginRequest);
    }
  };

  const captchaMutation = useMutation<CaptchaValidateResponse, ApiError, CaptchaValidateRequest>(
    (payload) => checkCaptcha(payload),
    {
      onSuccess: () => {
        if (formData) handleSubmitWithoutCaptcha(formData);
        setLoginError('');
        setLogin('');
        // setFormData(null);
      },
      onError: (err) => {
        if (err.type === 'CaptchaRequired' || err.type === 'UnauthorizedCaptcha')
          setCaptcha(err.captcha);
        if (err.code === '500') setLoginError(localeLogin[currentLocale].errors.jwtExpired);
      },
      onSettled: () => {
        setEmail('');
        setPassword('');
        setUid('');
        setUsername('');
      },
    },
  );

  const handleSubmit = (dataItem: FormDataValue): void => {
    setLoginError('');
    setFormData(dataItem);
    if (captcha) {
      setCaptcha('');
      const { captcha: captchaItem } = dataItem;
      captchaMutation.mutateAsync({ code: captchaItem || '' });
    } else handleSubmitWithoutCaptcha(dataItem);
  };

  const handleSubmitTfaCode = (dataItem: FormDataValue): void => {
    if (type === 'local' || type === 'ldap' || type === 'activedirectory') {
      tfaMutation.mutateAsync({
        ...dataItem,
      } as unknown as User2FALoginRequest);
    }
  };

  useEffect(() => {
    if (!isExpiredPassword) {
      setEmail('');
    }
  }, [isExpiredPassword]);

  return (
    <AxiosAuthInterceptor>
      <div className={styles.login_page}>
        <div className={styles.login_form}>
          {isExpiredPassword ? (
            <ChangePasswordForm />
          ) : (
            <div style={{ display: 'flex' }}>
              <div className="login-form__fields">
                <TabStrip className="image registry" selected={selected} onSelect={handleSelect}>
                  <TabStripTab
                    disabled={!(selected === 0) && isTwoFactoredAuthPage}
                    title={localeLogin[currentLocale].localAccount}
                  >
                    <LoginForm
                      mutationError={mutation.error}
                      handleSubmit={handleSubmit}
                      handleSubmitTfaCode={handleSubmitTfaCode}
                    />
                  </TabStripTab>
                  <TabStripTab disabled={!(selected === 1) && isTwoFactoredAuthPage} title="LDAP">
                    <LoginForm
                      mutationError={mutation.error}
                      handleSubmit={handleSubmit}
                      handleSubmitTfaCode={handleSubmitTfaCode}
                    />
                  </TabStripTab>
                  <TabStripTab disabled={!(selected === 2) && isTwoFactoredAuthPage} title="AD">
                    <LoginForm
                      mutationError={mutation.error}
                      handleSubmit={handleSubmit}
                      handleSubmitTfaCode={handleSubmitTfaCode}
                    />
                  </TabStripTab>
                </TabStrip>
              </div>
            </div>
          )}
        </div>
        <div className={styles.login_back}>
          <span>
            <img src={`${process.env.PUBLIC_URL}/images/login-logo.svg`} alt="" />
          </span>
          <div className={styles.gradient} />
        </div>
      </div>
    </AxiosAuthInterceptor>
  );
}
