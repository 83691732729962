import {
  createContext,
  JSXElementConstructor,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export interface IAuthContext {
  isDisableConfirm: boolean;
  setDisableConfirm: (v: boolean) => void;
  accessToken: string | null;
  setAccessToken: (v: string) => void;
  stayOn: boolean;
  setStayOn: React.Dispatch<React.SetStateAction<boolean>>;
  email: string | undefined;
  setEmail: (email: string | undefined) => void;
  password: string | undefined;
  setPassword: (password: string | undefined) => void;
  username: string;
  setUsername: (username: string) => void;
  uid: string;
  setUid: (uid: string) => void;
  login: string;
  setLogin: (login: string) => void;
  isExpiredPassword: boolean;
  setExpiredPassword: (v: boolean) => void;
  type: string;
  setType: (type: string) => void;
  isTwoFactoredAuthPage: boolean;
  setTwoFactoredAuthPage: (v: boolean) => void;
  loginError: string;
  setLoginError: (v: string) => void;
  captcha: string | undefined;
  setCaptcha: (v: string | undefined) => void;
  incIncorrectAttempt: () => void;
}

const AuthContext = createContext<IAuthContext | null>(null);

export function AuthProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const [attemptCount, setAttemptCount] = useState(0);
  // const timer: NodeJS.Timeout | null = null;

  const [isDisableConfirm, setDisableConfirm] = useState(false);

  const [accessToken, setAccessToken] = useState<string | null>(null);

  const [username, setUsername] = useState<string>('');

  const [uid, setUid] = useState<string>('');

  const [login, setLogin] = useState<string>('');

  const [email, setEmail] = useState<string | undefined>(undefined);

  const [password, setPassword] = useState<string | undefined>(undefined);

  const [stayOn, setStayOn] = useState<boolean>(false);

  const [type, setType] = useState<string>('local');

  const [isTwoFactoredAuthPage, setTwoFactoredAuthPage] = useState<boolean>(false);

  const [loginError, setLoginError] = useState<string>('');

  const [isExpiredPassword, setExpiredPassword] = useState<boolean>(false);

  const [captcha, setCaptcha] = useState<string | undefined>(undefined);

  const incIncorrectAttempt = (): void => {
    setAttemptCount((prev) => prev + 1);
  };

  useEffect(() => {
    if (attemptCount >= 4) {
      setDisableConfirm(true);
      setLoginError('Слишком много попыток. Попробуйте через 30 сек');
      setTimeout(() => {
        setAttemptCount(0);
        setDisableConfirm(false);
        setLoginError('');
      }, 30000);
    }
  }, [attemptCount]);

  const value = useMemo(
    () => ({
      isDisableConfirm,
      setDisableConfirm,
      accessToken,
      setAccessToken,
      type,
      setType,
      stayOn,
      setStayOn,
      email,
      setEmail,
      password,
      setPassword,
      username,
      setUsername,
      uid,
      setUid,
      login,
      setLogin,
      isExpiredPassword,
      setExpiredPassword,
      isTwoFactoredAuthPage,
      setTwoFactoredAuthPage,
      loginError,
      setLoginError,
      captcha,
      setCaptcha,
      incIncorrectAttempt,
    }),
    [
      isDisableConfirm,
      accessToken,
      type,
      stayOn,
      email,
      password,
      username,
      uid,
      login,
      isExpiredPassword,
      isTwoFactoredAuthPage,
      loginError,
      captcha,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuthContext = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuthContext must be used inside the AuthProvider.Provider.');
  }

  return context;
};
