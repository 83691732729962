import type { ReactElement } from 'react';
import localeUser from '../../../../utils/i18n/userLocales/addUserForm.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function PasswordToolTip({
  isLength,
  isNumber,
  isLetter,
  isCapital,
  isSpec,
  styles,
}: {
  isLength: boolean;
  isNumber: boolean;
  isLetter: boolean;
  isCapital: boolean;
  isSpec: boolean;
  styles?: React.CSSProperties;
}): ReactElement {
  return (
    <div
      style={{
        position: 'absolute',
        background: '#eee',
        top: '-12px',
        right: '0',
        zIndex: '2',
        padding: '8px 12px',
        borderRadius: '8px',
        ...styles,
      }}
    >
      {localeUser[currentLocale].securityRequirementsToolTip.info}
      <div>
        <span
          style={{ color: isLength ? '#00850b' : '#f20c0c' }}
          className={`k-icon ${isLength ? 'k-i-check' : 'k-i-close'}`}
        />{' '}
        {localeUser[currentLocale].securityRequirementsToolTip.length}
      </div>
      <div>
        <span
          style={{ color: isNumber ? '#00850b' : '#f20c0c' }}
          className={`k-icon ${isNumber ? 'k-i-check' : 'k-i-close'}`}
        />{' '}
        {localeUser[currentLocale].securityRequirementsToolTip.number}
      </div>
      <div>
        <span
          style={{ color: isLetter ? '#00850b' : '#f20c0c' }}
          className={`k-icon ${isLetter ? 'k-i-check' : 'k-i-close'}`}
        />{' '}
        {localeUser[currentLocale].securityRequirementsToolTip.letter}
      </div>
      <div>
        <span
          style={{ color: isCapital ? '#00850b' : '#f20c0c' }}
          className={`k-icon ${isCapital ? 'k-i-check' : 'k-i-close'}`}
        />{' '}
        {localeUser[currentLocale].securityRequirementsToolTip.capitalLetter}
      </div>
      <div>
        <span
          style={{ color: isSpec ? '#00850b' : '#f20c0c' }}
          className={`k-icon ${isSpec ? 'k-i-check' : 'k-i-close'}`}
        />{' '}
        {localeUser[currentLocale].securityRequirementsToolTip.specs}
      </div>
    </div>
  );
}
