import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import { WrapperMenu } from './WrapperMenu';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DiskNetDevices({ data }: { data: InventorizationResponse }): React.ReactElement {
  if ((data.type === 'router' || data.type === 'firewall') && data.class === 'mikrotik')
    return (
      <WrapperMenu title={locale[currentLocale].disks.title}>
        <table className="column">
          <tbody>
            <tr>
              <td>{locale[currentLocale].memoryNetDevices.total}</td>
              {data.result.disk?.total ? (
                <td>
                  {Math.round(Number(data.result.disk?.total) / 1024 / 1024)}{' '}
                  {locale[currentLocale].memoryNetDevices.sizeMb}
                </td>
              ) : (
                <td>-</td>
              )}
            </tr>
            <tr>
              <td>{locale[currentLocale].memoryNetDevices.free}</td>
              {data.result.disk?.free ? (
                <td>
                  {Math.round(Number(data.result.disk?.free) / 1024 / 1024)}{' '}
                  {locale[currentLocale].memoryNetDevices.sizeMb}
                </td>
              ) : (
                <td>-</td>
              )}
            </tr>
            <tr>
              <td>Bad blocks</td>
              {data.result.disk?.badBlocks ? <td>{data.result.disk?.badBlocks}</td> : <td>-</td>}
            </tr>
          </tbody>
        </table>
      </WrapperMenu>
    );

  return <div />;
}
