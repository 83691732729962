import React, { createContext, useState, useMemo, useContext, useEffect, useCallback } from 'react';
import type { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import type { Account } from '../types/__generated/on-premise-solution/api/account.v1';
import type { IChildrenNodes } from '../utils/helpers/types';
import { useCommonContext } from './useCommonContext';
import localeUserList from '../utils/i18n/userLocales/userList.json';
import { useMaintenanceWindowsContext } from './useMaintenanceWindowsContext';
import { convertFromActionsToPeriods } from '../utils/helpers/maintenance-windows-helper';
import type { AccountDeletionResponse } from '../types/__generated/on-premise-solution/api/accountDeletionResponse.v1';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import {
  createAccount,
  deleteAccount,
  editAccount as editAccountFunction,
} from '../services/account-service';
import type { AccountCreationResponse } from '../types/__generated/on-premise-solution/api/accountCreationResponse.v1';
import type { AccountCreationRequest } from '../types/__generated/on-premise-solution/api/accountCreationRequest.v1';
import type { AccountUpdateResponse } from '../types/__generated/on-premise-solution/api/accountUpdateResponse.v1';
import type { MaintenanceWindowsField } from '../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';

export type AccountEdit = Pick<Account, 'accountId' | 'name' | 'maintenanceWindows'>;

export type AccountModalContent = {
  isOpen: boolean;
  setOpen: (v: boolean) => void;
  isEdit: boolean;
  name: string;
  setName: (v: string) => void;
  setEdit: (v: boolean) => void;
  accountData: AccountEdit | null;
  setAccountData: (v: AccountEdit | null) => void;
  isAddAccount: boolean;
  setAddAccount: (v: boolean) => void;
  accountForm: IAccountForm[] | null;
  setAccountForm: React.Dispatch<React.SetStateAction<IAccountForm[] | null>>;
  deleteAccountFromTask: (
    accountId: string | undefined,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  exitAccountFrom: () => void;
  delAllAccounts: () => void;
  addSelectedAccounts: () => void;
  accountId: { id: string; name: string };
  setAccountId(accountId: { id: string; name: string }): void;
  changeDropDownAccount: (event: DropDownListChangeEvent) => void;
  editAccount: (data: AccountEdit | null) => void;
  mutationNewAccount: UseMutationResult<
    AccountCreationResponse,
    ApiError,
    AccountCreationRequest,
    unknown
  >;
  mutationEditAccount: UseMutationResult<
    AccountUpdateResponse,
    ApiError,
    AccountUpdateResponse,
    unknown
  >;
  mutationDeleteAccount: UseMutationResult<
    AccountDeletionResponse | null,
    ApiError,
    string,
    unknown
  >;
  setAccountPatch: () => void;
  editMaintenanceWindows: (data: AccountEdit | null, isFromMenu?: boolean) => void;
};

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUserList;
export interface IAccountForm {
  accountId: string | undefined;
  name: string | undefined;
}

const AccountModalContext = createContext<AccountModalContent | null>(null);

export function AccountModalProvider({ children }: IChildrenNodes): React.ReactElement {
  const queryClient = useQueryClient();

  // Contexts

  const { setOpenForm } = useCommonContext();
  const { setWindowsListData } = useMaintenanceWindowsContext();
  const { entityId, closeWindow, setPatchFunction, setTableOpen } = useMaintenanceWindowsContext();

  // States
  const [isOpen, setOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [name, setName] = useState<string>('');
  const [accountData, setAccountDataState] = useState<AccountEdit | null>(null);
  const [isAddAccount, setAddAccount] = useState(false);
  const [accountForm, setAccountForm] = useState<IAccountForm[] | null>(null);
  const [accountId, setAccountId] = useState<AccountModalContent['accountId']>({
    id: '000',
    name: localeUserList[currentLocale].modal.placeholderAccountId,
  });

  // Mutations

  const mutationNewAccount = useMutation<AccountCreationResponse, ApiError, AccountCreationRequest>(
    (payload) => createAccount(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('accountList');
        setOpen(false);
      },
    },
  );

  const mutationEditAccount = useMutation<AccountUpdateResponse, ApiError, AccountUpdateResponse>(
    (payload) => editAccountFunction(payload),
  );

  const mutationDeleteAccount = useMutation<
    AccountDeletionResponse | null,
    ApiError,
    Account['accountId']
  >((payload) => deleteAccount(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('accountList');
    },
  });

  // Functions
  const editAccount = useCallback(
    (data: AccountEdit | null): void => {
      setAccountDataState(data);
      setWindowsListData(convertFromActionsToPeriods(data?.maintenanceWindows));
      setEdit(true);
      setOpen(true);
    },
    [setWindowsListData],
  );

  const editMaintenanceWindows = useCallback(
    (data: AccountEdit | null, isFromMenu?: boolean): void => {
      if (!isFromMenu) {
        setAccountDataState(data);
        setEdit(true);
      }
      setWindowsListData(convertFromActionsToPeriods(data?.maintenanceWindows));
      setTableOpen(true);
    },
    [setTableOpen, setWindowsListData],
  );

  const setAccountData = useCallback(
    (data: AccountEdit | null): void => {
      setAccountDataState(data);
      setWindowsListData(convertFromActionsToPeriods(data?.maintenanceWindows));
    },
    [setWindowsListData],
  );

  const deleteAccountFromTask = useCallback(
    (id: string | undefined, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (accountForm && accountForm.length > 0) {
        setAccountForm(accountForm?.filter((a) => a.accountId !== id));
      }
    },
    [accountForm],
  );

  const exitAccountFrom = useCallback(() => {
    setAccountForm(null);
    setAddAccount(false);
  }, []);

  const delAllAccounts = useCallback((): void => {
    setAccountForm(null);
  }, []);

  const addSelectedAccounts = useCallback((): void => {
    setAccountForm(accountForm);
    setAddAccount(false);
  }, [accountForm]);

  const changeDropDownAccount = useCallback(
    (event: DropDownListChangeEvent): void => {
      setAccountId({
        id: event.target.value.id,
        name: event.target.value.name,
      });
    },
    [setAccountId],
  );

  const setAccountPatch = useCallback((): void => {
    setPatchFunction((maintenancePayload: MaintenanceWindowsField | undefined): void => {
      mutationEditAccount
        .mutateAsync({
          accountId: entityId.current,
          maintenanceWindows: (maintenancePayload ||
            undefined) as AccountUpdateResponse['maintenanceWindows'],
        } as unknown as AccountUpdateResponse)
        .then((data) => {
          closeWindow();
          setWindowsListData(convertFromActionsToPeriods(data.maintenanceWindows));
          queryClient.invalidateQueries('accountList');
        });
    });
  }, [entityId.current]);

  // useEffects
  useEffect((): void => {
    setOpenForm(isOpen);
  }, [isOpen, setOpenForm]);

  const value = useMemo(
    () => ({
      isOpen,
      setOpen,
      isEdit,
      setEdit,
      name,
      setName,
      accountData,
      setAccountData,
      isAddAccount,
      setAddAccount,
      accountForm,
      setAccountForm,
      deleteAccountFromTask,
      exitAccountFrom,
      delAllAccounts,
      addSelectedAccounts,
      accountId,
      setAccountId,
      changeDropDownAccount,
      mutationNewAccount,
      mutationEditAccount,
      mutationDeleteAccount,
      editAccount,
      setAccountPatch,
      editMaintenanceWindows,
    }),
    [
      isOpen,
      isEdit,
      name,
      accountData,
      setAccountData,
      isAddAccount,
      accountForm,
      deleteAccountFromTask,
      exitAccountFrom,
      delAllAccounts,
      addSelectedAccounts,
      accountId,
      changeDropDownAccount,
      mutationNewAccount,
      mutationEditAccount,
      mutationDeleteAccount,
      editAccount,
      setAccountPatch,
      editMaintenanceWindows,
    ],
  );

  return <AccountModalContext.Provider value={value}>{children}</AccountModalContext.Provider>;
}
export const useAccountModalContext = (): AccountModalContent => {
  const context = useContext(AccountModalContext);
  if (context === null) {
    throw new Error('ModalContext must be used inside the ModalContext.Provider.');
  }

  return context;
};
