/* eslint-disable no-nested-ternary */
import type { MenuSelectEvent } from '@progress/kendo-react-layout';
import type { UseQueryResult } from 'react-query';
import { useHostModalContext } from '../useHostModalContext';
import { useTaskContext } from '../useTaskContext';
import localeNewTask from '../../utils/i18n/taskLocale/newTask.json';
import { getAccountId, getUserData } from '../../services/local-storage-service';
import type { TaskExecutionDashboardResponse } from '../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import { useRegisterContext } from '../useRegisterContext';
import localeHostDescription from '../../utils/i18n/assetLocales/hostDescription.json';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import { getAgentInstaller } from '../../services/asset-service';
import { useHostCreateContext } from '../useHostCreateContext';
import localeTask from '../../utils/i18n/taskLocale/task.json';
import type { AuditDashboardResponse } from '../../types/__generated/on-premise-solution/api/auditDashboardResponse.v1';
import type { ICreateTask, ICreateTaskHostRegistry, IHostForm } from '../../utils/helpers/types';
import { useNetDeviceContext } from '../useNetDeviceContext';
import type { AssetHostDashboardResponse } from '../../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import { useTaskAddContext } from '../useTaskAddContext';
import type { TaskActionDashboardResponse } from '../../types/__generated/on-premise-solution/api/taskActionDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;
export function useCreateTask(): ICreateTaskHostRegistry {
  const { setHostForm, hostForm } = useHostModalContext();

  const { setDeviceForm } = useNetDeviceContext();

  const { setOpenDialog, setTaskRepeatData } = useTaskContext();

  const userName = getUserData();

  const accountId = getAccountId();

  const { setHostCreateData } = useHostCreateContext();

  const { setRegistryForm, setImageForm } = useRegisterContext();

  const { setHtmlFormat, setPdfFormat, setTaskSource } = useTaskAddContext();

  const createTaskForAsset = (createTaskData: ICreateTask): void => {
    const { val, data, assetId, hostname } = createTaskData;
    if (val === 'default') {
      setHostForm([
        {
          assetId,
          hostname: data?.type === 'host' ? data.latestInventorization?.hostname : undefined,
        },
      ]);
      setTaskRepeatData({
        taskName: `${
          localeNewTask[currentLocale].form.defaultTaskName
        }_${new Date().toLocaleDateString()}_${userName?.email}`,
        taskType: 'asset',
        actionI: '',
        actionA: '',
        actionWl: 'audit.useBulletinWhitelists',
      });
    } else if (val === 'package_update') {
      setHostForm([
        {
          assetId,
          hostname: hostname || undefined,
        },
      ]);
      setTaskRepeatData({
        taskName: `${
          localeNewTask[currentLocale].form.defaultTaskName
        }_${new Date().toLocaleDateString()}_${userName?.email}`,
        taskType: 'asset',
        actionI: '',
        actionA: '',
        actionPackagetU: 'package_update',
      });
    } else if (val === 'windows_software_management') {
      setTaskRepeatData({
        taskName: `${
          localeNewTask[currentLocale].form.defaultTaskName
        }_${new Date().toLocaleDateString()}_${userName?.email}`,
        taskType: 'asset',
        actionI: '',
        actionA: '',
        actionSoftwareManagement: 'windows_software_management',
      });
      if (!hostForm && assetId) {
        setHostForm([
          {
            assetId,
            hostname: hostname || undefined,
          },
        ]);
      }
    } else if (val === 'kb_install') {
      setHostForm([
        {
          assetId,
          hostname: hostname || undefined,
        },
      ]);
      setTaskRepeatData({
        taskName: `${
          localeNewTask[currentLocale].form.defaultTaskName
        }_${new Date().toLocaleDateString()}_${userName?.email}`,
        taskType: 'asset',
        actionI: '',
        actionA: '',
        actionKbInstall: 'kb_install',
      });
    } else if (
      val === 'inventorization' &&
      (data?.type === 'router' ||
        data?.type === 'firewall' ||
        data?.type === 'switch' ||
        data?.type === 'hypervisor')
    ) {
      setDeviceForm([
        {
          assetId,
          hostname: data.latestInventorization?.hostname
            ? data.latestInventorization?.hostname
            : undefined,
        },
      ]);
      setTaskRepeatData({
        taskName: `${
          localeNewTask[currentLocale].form.defaultTaskName
        }_${new Date().toLocaleDateString()}_${userName?.email}`,
        taskType: 'device',
        actionI: 'inventorization',
        actionA: '',
      });
    } else if (val === 'inventorization') {
      setHostForm([
        {
          assetId,
          hostname:
            data?.type === 'host'
              ? data.latestInventorization?.hostname
              : data && 'result' in data
              ? data.result.system_info.hostname
              : undefined,
        },
      ]);
      setTaskRepeatData({
        taskName: `${
          localeNewTask[currentLocale].form.defaultTaskName
        }_${new Date().toLocaleDateString()}_${userName?.email}`,
        taskType: 'asset',
        actionI: 'inventorization',
        actionA: '',
      });
    } else if (val === 'agent_update' && data?.type === 'host' && data.latestInventorization) {
      setHostForm([
        {
          assetId: data.assetId,
          hostname: data.type === 'host' ? data.latestInventorization.hostname : undefined,
        },
      ]);
      setTaskRepeatData({
        taskName: `${
          localeNewTask[currentLocale].form.defaultTaskName
        }_${new Date().toLocaleDateString()}_${userName?.email}`,
        taskType: 'asset',
        actionI: '',
        actionA: '',
        actionAgentU: 'agent_update',
      });
    } else if (val === 'agent_update') {
      setHostForm([
        {
          assetId,
          hostname: data?.type === 'host' ? data.latestInventorization?.hostname : undefined,
        },
      ]);
      setTaskRepeatData({
        taskName: `${
          localeNewTask[currentLocale].form.defaultTaskName
        }_${new Date().toLocaleDateString()}_${userName?.email}`,
        taskType: 'asset',
        actionI: '',
        actionA: '',
        actionAgentU: val,
      });
    }
    setOpenDialog(true);
  };

  const createReportTaskDescription = (data: TaskExecutionDashboardResponse | undefined): void => {
    if (data) {
      let assetsValue = [] as IHostForm[];
      let assetGroupValue = [] as IHostForm[];
      if ('assets' in data.scope && data.scope.assets) {
        assetsValue = data.scope.assets.map((a) => {
          return {
            assetId: a.assetId,
            hostname: 'hostname' in a ? a.hostname : a.imageNames[0],
          };
        });
        setHostForm([...assetsValue]);
      }
      if ('assetGroups' in data.scope && data.scope.assetGroups) {
        assetGroupValue = data.scope.assetGroups.map((a) => {
          return {
            assetGroupId: a.assetGroupId,
            name: a.name,
          };
        });
        setHostForm([...assetsValue, ...assetGroupValue]);
      }
      setTaskRepeatData({
        taskName:
          ('assets' in data.scope && data.scope.assets && data.scope.assets?.length > 1) ||
          ('assetGroups' in data.scope &&
            data.scope.assetGroups &&
            data.scope.assetGroups?.length > 1)
            ? localeTask[currentLocale].form.actionsValues.single_reports
            : localeTask[currentLocale].form.actionsValues.single_report,
        taskType: 'report',
        actionGR: 'group_report' in data.actions ? 'group_report' : '',
        actionCR: 'single_report' in data.actions ? 'single_report' : '',
      });
    }
    if (data && data.taskType === 'report' && data.actions && 'single_report' in data.actions) {
      if (data.actions.single_report?.fileFormat.find((f) => f === 'pdf')) {
        setPdfFormat(true);
      }
    }

    if (data && data.taskType === 'report' && data.actions && 'group_report' in data.actions) {
      if (data.actions.group_report?.fileFormat.find((f) => f === 'pdf')) {
        setPdfFormat(true);
      }
    }

    if (data && data.taskType === 'report' && data.actions && 'single_report' in data.actions) {
      if (data.actions.single_report?.fileFormat.find((f) => f === 'html')) {
        setHtmlFormat(true);
      }
    }

    if (data && data.taskType === 'report' && data.actions && 'group_report' in data.actions) {
      if (data.actions.group_report?.fileFormat.find((f) => f === 'html')) {
        setHtmlFormat(true);
      }
    }

    setOpenDialog(true);
  };

  const handleAsset = async (
    e: MenuSelectEvent,
    queryDashboardAssetId: UseQueryResult<AssetHostDashboardResponse, ApiError>,
  ): Promise<void> => {
    if (
      e.item.text === localeHostDescription[currentLocale].agentReinstall &&
      queryDashboardAssetId.data &&
      queryDashboardAssetId.data.type === 'host' &&
      (queryDashboardAssetId.data.agent || queryDashboardAssetId.data.latestInventorization) &&
      e.itemId !== '0'
    ) {
      const osType =
        queryDashboardAssetId.data.type === 'host'
          ? ((queryDashboardAssetId.data.latestInventorization?.os.type ||
              queryDashboardAssetId.data.agent?.osType) as 'linux' | 'windows')
          : undefined;
      const id = queryDashboardAssetId.data.assetId as string;
      const dataAssetDashboard = await getAgentInstaller(osType, id);
      setHostCreateData({
        ...dataAssetDashboard,
        assetType: osType,
        queryFn: undefined,
        hostname:
          queryDashboardAssetId.data.type === 'host'
            ? queryDashboardAssetId.data.latestInventorization?.hostname || ''
            : undefined,
        accountId,
      });
    } else if (
      localeHostDescription[currentLocale].agentUpdate &&
      queryDashboardAssetId.data &&
      queryDashboardAssetId.data.type === 'host' &&
      queryDashboardAssetId.data.latestInventorization &&
      e.itemId !== '0'
    ) {
      createTaskForAsset({
        val: 'agent_update',
        data: queryDashboardAssetId.data,
      });
    }
  };

  const createReportTaskDetails = (data: TaskActionDashboardResponse | undefined): void => {
    if (
      data &&
      data.type === 'inventorization' &&
      typeof data.assetId === 'string' &&
      data.assets &&
      data.assets[0].type === 'host' &&
      'latestInventorization' in data.assets[0] &&
      data.assets[0].latestInventorization &&
      data.assets[0].latestInventorization.hostname
    ) {
      setHostForm([
        {
          assetId: data?.assetId,
          hostname: data.assets[0].latestInventorization.hostname,
        },
      ]);
      setTaskRepeatData({
        taskName: localeTask[currentLocale].form.actionsValues.single_report,
        taskType: 'report',
        actionGR: '',
        actionCR: 'single_report',
      });
    }

    setOpenDialog(true);
  };

  const createReportTaskVmDetails = (data: AuditDashboardResponse | undefined): void => {
    if (
      data &&
      data.assetId &&
      typeof data.assetId === 'string' &&
      data.asset &&
      data.asset.type === 'host' &&
      'latestInventorization' in data.asset &&
      data.asset.latestInventorization &&
      data.asset.latestInventorization.hostname
    ) {
      setHostForm([
        {
          assetId: data?.assetId,
          hostname: data.asset.latestInventorization.hostname,
        },
      ]);
      setTaskRepeatData({
        taskName: localeTask[currentLocale].form.actionsValues.single_report,
        taskType: 'report',
        actionGR: '',
        actionCR: 'single_report',
      });
    }

    setOpenDialog(true);
  };
  const createReportHost = (
    assetId: string | undefined,
    hostname: string | undefined | null,
  ): void => {
    if (assetId && hostname) {
      setHostForm([
        {
          assetId,
          hostname,
        },
      ]);
      setTaskRepeatData({
        taskName: `${localeTask[currentLocale].form.actionsValues.single_report}${hostname}`,
        taskType: 'report',
        actionGR: '',
        actionCR: 'single_report',
      });
    }

    setOpenDialog(true);
  };

  const createReportImage = (
    assetId: string | undefined,
    hostname: string | undefined | null,
  ): void => {
    if (assetId && hostname) {
      setTaskSource('image');
      setImageForm([
        {
          assetId,
          imageNames: hostname,
        },
      ]);
      setTaskRepeatData({
        taskName: `${localeTask[currentLocale].form.actionsValues.single_report_image}${hostname}`,
        taskType: 'report',
        actionGR: '',
        actionCR: 'single_report',
      });
    }

    setOpenDialog(true);
  };

  const createReportDevice = (
    assetId: string | undefined,
    hostname: string | undefined | null,
  ): void => {
    if (assetId && hostname) {
      setTaskSource('device');
      setDeviceForm([
        {
          assetId,
          hostname,
        },
      ]);
      setTaskRepeatData({
        taskName: `${localeTask[currentLocale].form.actionsValues.single_report_device}${hostname}`,
        taskType: 'report',
        actionGR: '',
        actionCR: 'single_report',
      });
    }

    setOpenDialog(true);
  };

  const createTaskForImage = (
    val: string,
    imageNames: string | undefined,
    imageId: string | undefined,
    assetId: string | undefined,
  ): void => {
    if (val === 'image') {
      setImageForm([
        {
          imageNames: imageNames || '',
          imageId,
          assetId: assetId || '',
        },
      ]);
      setTaskRepeatData({
        taskName: `${
          localeNewTask[currentLocale].form.defaultTaskName
        }_${new Date().toLocaleDateString()}_${userName?.email}`,
        taskType: 'image',
        actionA: 'audit',
        // actionAImage: 'audit_image',
      });
    }
    setOpenDialog(true);
  };

  const createTaskForRegistry = (
    val: string,
    registryId: string,
    name: string | undefined,
  ): void => {
    if (val === 'registry') {
      setRegistryForm([
        {
          registryId,
          name: name || '',
        },
      ]);
      setTaskRepeatData({
        taskName: `${
          localeNewTask[currentLocale].form.defaultTaskName
        }_${new Date().toLocaleDateString()}_${userName?.email}`,
        taskType: 'image',
        actionA: 'audit',
        actionAImage: 'audit_image',
      });
    }
    setOpenDialog(true);
  };

  return {
    createTaskForAsset,
    handleAsset,
    createReportTaskDescription,
    createReportTaskDetails,
    createReportTaskVmDetails,
    createReportHost,
    createReportImage,
    createReportDevice,
    createTaskForImage,
    createTaskForRegistry,
  };
}
