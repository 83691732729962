/* eslint-disable react/jsx-indent */
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { ErrorBoundary } from 'react-error-boundary';
import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { useQuery, useQueryClient } from 'react-query';
import { useEffect, useRef, useState } from 'react';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import localeHostGroups from '../../utils/i18n/assetLocales/assetGroups.json';
import { FILTER_INITIAL, PAGEABLE_DATA } from '../../utils/helpers/constants';
import type { IDataHooks, IPageState } from '../../utils/helpers/types';
import { FILTER_OPERATORS } from '../../utils/helpers/constant-filter-operators';
import { handleSort } from '../../utils/sorting-in-table';
import { CellGroupName } from './cellsCommonGroupList/CellGroupName';
import { CellCommonTd } from './cellsCommonGroupList/CellCommonTd';
import { CellMenuGroup } from './cellsCommonGroupList/CellMenuGroup';
import { BoundaryErrorComponent } from './BoundaryErrorComponent';
import { CellSelectGroups } from './cellsCommonGroupList/CellSelectGroups';
import { CellToCheckGr } from './cellsCommonGroupList/CellToCheckGr';
import { CellGroupDescription } from './cellsCommonGroupList/CellGroupDescription';
import type { IUrlParamsAssetGr } from '../../utils/helpers/host-group-list-helper';
import { handleFilteringTest, IFilterVal } from '../../utils/filtering-in-table-test';
import { useCustomSearch } from '../../hooks/components/useCustomSearch';
import { useFilter } from '../../hooks/components/useFilter';
import { usePageChange } from '../../hooks/components/usePageChange';
import { useNotificationContext } from '../../hooks/useNotificationContext';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import { getHostDynamicGroupList, getHostGroupList } from '../../services/asset-service';
import { handleError } from '../../utils/errors';
import {
  FILTER_DYNAMIC_LIST_GROUP,
  FILTER_LIST_GROUP,
} from '../../utils/helpers/constant-serv-filter-host';
import { Input } from './baseElements/Input';
import { Button } from './baseElements/Button';
import {
  handleAddAllDynamicGroups,
  handleAddAllGroups,
} from '../../utils/helpers/add-host-list-helper';
import { useHostModalContext } from '../../hooks/useHostModalContext';
import type { AssetGroupsDashboardResponse } from '../../types/__generated/on-premise-solution/api/assetGroupsDashboardResponse.v1';
import { CellSelectGroup } from './cellsCommonGroupList/CellSelectGroup';
import type { AssetDynamicGroupsDashboardResponse } from '../../types/__generated/on-premise-solution/api/assetDynamicGroupsDashboardResponse.v1';
import { CellDynamicGroupAssetsInfo } from '../features/DynamicGroup/CellDynamicGroupAssetsInfo';
import { CellSelectDynamicGroups } from './cellsCommonGroupList/CellSelectDynamicGroups';
import { useGroupContext } from '../../hooks/useGroupContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostGroups;

export function CommonGroupList({
  dataForHooks,
  urlParams,
  parentComponent,
  taskElement,
}: {
  dataForHooks: IDataHooks;
  urlParams: IUrlParamsAssetGr;
  parentComponent?: string;
  taskElement?: string;
}): React.ReactElement {
  const queryClient = useQueryClient();

  const [customSearch, setCustomSearch] = useState('');

  const [sort, setSort] = useState<SortDescriptor[]>([{ field: 'createdAt', dir: 'desc' }]);

  const [page, setPage] = useState<IPageState>(urlParams.page);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const { createNotification } = useNotificationContext();

  const { hostFormTemp, setHostFormTemp } = useHostModalContext();

  const { dynamicGroupForm, setDynamicGroupForm } = useGroupContext();

  const queryGr = useQuery<AssetGroupsDashboardResponse, ApiError>(
    ['HostGroupList', page, filterVal, sort],
    () => getHostGroupList(page, filterVal, sort, dataForHooks.componentName === 'HostGroupList'),
    {
      keepPreviousData: true,
      enabled:
        dataForHooks.componentName === 'HostGroupList' ||
        dataForHooks.componentName === 'AddHostGroups' ||
        dataForHooks.componentName === 'AddHostToGroupModal',
    },
  );

  const queryDynamicGr = useQuery<AssetDynamicGroupsDashboardResponse, ApiError>(
    ['HostDynamicGroupList', page, filterVal, sort],
    () => getHostDynamicGroupList(page, filterVal, sort),
    {
      keepPreviousData: true,
      enabled:
        dataForHooks.componentName === 'DynamicGroupList' ||
        dataForHooks.componentName === 'DynamicGroupListForForm',
    },
  );

  useEffect(() => {
    if (
      dataForHooks.componentName === 'HostGroupList' ||
      dataForHooks.componentName === 'AddHostGroups' ||
      dataForHooks.componentName === 'AddHostToGroupModal'
    ) {
      queryClient.invalidateQueries('HostGroupList');
    }
    if (
      dataForHooks.componentName === 'DynamicGroupList' ||
      dataForHooks.componentName === 'DynamicGroupListForForm'
    ) {
      queryClient.invalidateQueries('HostDynamicGroupList');
    }
  }, [dataForHooks.componentName, queryClient]);

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter?.filters, page.skip, filter]);

  useEffect(() => {
    if (queryGr.error) {
      handleError(queryGr.error, createNotification);
    }
  }, [queryGr.isError, queryGr.error, createNotification]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  const dataGr = queryGr.data;

  const dataDynamicGr = queryDynamicGr.data;

  const resetStaticGroups = (): void => {
    const assetTypeHost = hostFormTemp?.filter((a) => !a.assetGroupId);
    if (assetTypeHost) setHostFormTemp(assetTypeHost);
  };

  return (
    <>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={onFilterChange}
              fields={
                dataForHooks.componentName === 'DynamicGroupList'
                  ? FILTER_DYNAMIC_LIST_GROUP[currentLocale]
                  : FILTER_LIST_GROUP[currentLocale]
              }
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={localeHostGroups[currentLocale].customSearchName}
                value={customSearch}
                onChange={(e): void => handleCustomSearch(e)}
              />
            )}
          </div>
        </IntlProvider>
      </LocalizationProvider>
      {!document.location.pathname.includes('assets/groups/static') &&
        !document.location.pathname.includes('assets/groups/dynamic') &&
        parentComponent !== 'CellSelectAssetGroup' &&
        dataForHooks.componentName === 'HostGroupList' && (
          <div className="common-group-actions">
            <Button
              onClick={(): void => handleAddAllGroups(dataGr?.data, hostFormTemp, setHostFormTemp)}
            >
              {localeHostGroups[currentLocale].form.btnAddAllGr}
            </Button>
            {hostFormTemp &&
              hostFormTemp.filter((gr) => !gr.assetId) &&
              hostFormTemp.filter((gr) => !gr.assetId).length > 0 && (
                <Button onClick={resetStaticGroups}>
                  {localeHostGroups[currentLocale].form.btnCancelAllGr} (
                  {hostFormTemp.filter((gr) => !gr.assetId).length})
                </Button>
              )}
          </div>
        )}
      {!document.location.pathname.includes('assets/groups/static') &&
        !document.location.pathname.includes('assets/groups/dynamic') &&
        parentComponent !== 'CellSelectAssetGroup' &&
        dataForHooks.componentName === 'DynamicGroupList' && (
          <div className="common-group-actions">
            <Button
              onClick={(): void =>
                handleAddAllDynamicGroups(
                  dataDynamicGr?.data,
                  dynamicGroupForm,
                  setDynamicGroupForm,
                )
              }
            >
              {localeHostGroups[currentLocale].form.btnAddAllGr}
            </Button>
            {dynamicGroupForm &&
              dynamicGroupForm.filter((gr) => gr.assetDynamicGroupId) &&
              dynamicGroupForm.filter((gr) => gr.assetDynamicGroupId).length > 0 && (
                <Button onClick={(): void => setDynamicGroupForm(null)}>
                  {localeHostGroups[currentLocale].form.btnCancelAllGr} (
                  {dynamicGroupForm.filter((gr) => gr.assetDynamicGroupId).length})
                </Button>
              )}
          </div>
        )}
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
            <Grid
              pageable={
                Number(
                  dataForHooks.componentName === 'HostGroupList' ||
                    dataForHooks.componentName === 'AddHostGroups' ||
                    dataForHooks.componentName === 'AddHostToGroupModal'
                    ? dataGr?.total
                    : dataDynamicGr?.total,
                ) > 15
                  ? PAGEABLE_DATA
                  : false
              }
              sortable
              sort={sort}
              data={
                dataForHooks.componentName === 'HostGroupList' ||
                dataForHooks.componentName === 'AddHostGroups' ||
                dataForHooks.componentName === 'AddHostToGroupModal'
                  ? dataGr
                  : dataDynamicGr
              }
              skip={page.skip}
              take={page.take}
              total={
                dataForHooks.componentName === 'HostGroupList'
                  ? Number(dataGr?.total)
                  : Number(dataDynamicGr?.total)
              }
              filterOperators={FILTER_OPERATORS}
              onPageChange={pageChange}
              onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
              scrollable="none"
              className={
                Number(
                  dataForHooks.componentName === 'HostGroupList'
                    ? dataGr?.total
                    : dataDynamicGr?.total,
                ) &&
                Number(
                  dataForHooks.componentName === 'HostGroupList'
                    ? dataGr?.total
                    : dataDynamicGr?.total,
                ) < 15
                  ? 'no-pageable-k-grid table'
                  : 'table'
              }
            >
              {(dataForHooks.componentName === 'AddHostGroups' ||
                (dataForHooks.componentName !== 'AddHostToGroupModal' &&
                  dataForHooks.componentName !== 'DynamicGroupList' &&
                  taskElement === 'AddGroupsForTask')) &&
                parentComponent !== 'CellSelectAssetGroup' && (
                  <Column field="addAssetGroup" title=" " cell={CellSelectGroups} width="40px" />
                )}
              {(dataForHooks.componentName === 'DynamicGroupListForForm' ||
                (dataForHooks.componentName === 'DynamicGroupList' &&
                  taskElement === 'AddGroupsForTask')) && (
                <Column
                  field="selectDynamicGroupsetGroup"
                  title=" "
                  cell={CellSelectDynamicGroups}
                  width="40px"
                />
              )}
              {parentComponent === 'CellSelectAssetGroup' && (
                <Column field="selectAssetGroup" title=" " cell={CellSelectGroup} width="40px" />
              )}
              {dataForHooks.componentName === 'AddHostToGroupModal' && (
                <Column
                  field="addAssetGroup"
                  title={localeHostGroups[currentLocale].form.groupAddForm}
                  cell={CellToCheckGr}
                  width="92px"
                  sortable={false}
                />
              )}
              {dataForHooks.componentName === 'AddHostToGroupModal' ||
              dataForHooks.componentName === 'AddHostGroups' ? (
                <Column
                  field="name"
                  title={localeHostGroups[currentLocale].form.name}
                  filter="text"
                />
              ) : (
                <Column
                  field="name"
                  title={localeHostGroups[currentLocale].form.name}
                  filter="text"
                  cell={CellGroupName}
                  width={dataForHooks.componentName === 'AddHostToGroupModal' ? '380px' : 'auto'}
                  className={dataForHooks.componentName === 'HostGroupList' ? 'static' : 'dynamic'}
                />
              )}
              {dataForHooks.componentName !== 'DynamicGroupList' &&
                dataForHooks.componentName !== 'DynamicGroupListForForm' && (
                  <Column
                    field="assetCount"
                    title={localeHostGroups[currentLocale].form.assetCount}
                    filter="text"
                    width={dataForHooks.componentName === 'AddHostToGroupModal' ? '134px' : '144px'}
                    className="number"
                  />
                )}
              {(dataForHooks.componentName === 'DynamicGroupList' ||
                dataForHooks.componentName === 'DynamicGroupListForForm') && (
                <Column
                  field="latestAssets.refreshedAt"
                  title={localeHostGroups[currentLocale].form.assetCount}
                  filter="text"
                  cell={CellDynamicGroupAssetsInfo}
                  width="192px"
                />
              )}
              <Column
                field="description"
                title={localeHostGroups[currentLocale].form.descriptionInTbl}
                filter="text"
                cell={CellGroupDescription}
              />
              <Column
                field="createdAt"
                title={localeHostGroups[currentLocale].form.createdAt}
                filter="text"
                cell={CellCommonTd}
                width="180px"
              />
              <Column
                field="createdBy"
                title={localeHostGroups[currentLocale].form.createdBy}
                filter="text"
                cell={CellCommonTd}
              />
              <Column
                field="modifiedAt"
                title={localeHostGroups[currentLocale].form.modifiedAt}
                filter="text"
                cell={CellCommonTd}
                width="180px"
              />
              <Column
                field="modifiedBy"
                title={localeHostGroups[currentLocale].form.modifiedBy}
                filter="text"
                cell={CellCommonTd}
              />
              {taskElement !== 'AddGroupsForTask' &&
                (dataForHooks.componentName === 'HostGroupList' ||
                  dataForHooks.componentName === 'DynamicGroupList') && (
                  <Column width="40px" cell={CellMenuGroup} />
                )}
            </Grid>
          </ErrorBoundary>
        </IntlProvider>
      </LocalizationProvider>
    </>
  );
}
