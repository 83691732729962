import type { GridCellProps } from '@progress/kendo-react-grid';
import { RadioButton, RadioButtonChangeEvent } from '@progress/kendo-react-inputs';
import { memo, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { useWhiteListContext } from '../../../hooks/useWhiteListContext';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import type { BulletinWhitelistDashboardResponse } from '../../../types/__generated/on-premise-solution/api/bulletinWhitelistDashboardResponse.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getWhiteLIstId } from '../../../services/settings-service';
import { useGroupContext } from '../../../hooks/useGroupContext';

function CellSelectWL(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { assetId } = useParams<{ assetId: string }>();

  const [selectedValue, setSelectedValue] = useState('');

  const { selectedWhitelist, setSelectedWhitelist } = useWhiteListContext();

  const { setHostForm } = useHostModalContext();

  const { setDynamicGroupForm } = useGroupContext();

  const handleChange = (e: RadioButtonChangeEvent): void => {
    setSelectedValue(e.value);
    setSelectedWhitelist([
      { name: dataItem.name, bulletinWhitelistId: dataItem.bulletinWhitelistId },
    ]);
  };

  useQuery<BulletinWhitelistDashboardResponse, ApiError>(
    ['bulletinWhitelistId', dataItem.bulletinWhitelistId],
    () => getWhiteLIstId(dataItem.bulletinWhitelistId),
    {
      enabled: !!selectedValue,
      onSuccess: (response) => {
        const assetsResp = response.scope.assets
          ?.map((a) => ({ assetId: a.assetId }))
          .filter((a) => a.assetId !== assetId);

        const staticGrResp = response.scope.assetGroups?.map((staticGr) => ({
          assetGroupId: staticGr.assetGroupId,
        }));

        const dynamicGrResp = response.scope.assetDynamicGroups?.map((dynamicGr) => ({
          name: '',
          assetDynamicGroupId: dynamicGr.assetDynamicGroupId,
        }));

        if (assetsResp && assetsResp.length > 0 && !staticGrResp) {
          setHostForm([
            {
              assetId,
            },
            ...assetsResp,
          ]);
        }

        if (dynamicGrResp && dynamicGrResp.length > 0) {
          setDynamicGroupForm(dynamicGrResp);
        }

        if (staticGrResp && staticGrResp.length > 0 && !assetsResp) {
          setHostForm([
            {
              assetId,
            },
            ...staticGrResp,
          ]);
        }

        if (staticGrResp && staticGrResp.length > 0 && assetsResp && assetsResp.length > 0) {
          setHostForm([
            {
              assetId,
            },
            ...staticGrResp,
            ...assetsResp,
          ]);
        }
      },
    },
  );

  return (
    <td>
      <RadioButton
        name="group1"
        value={dataItem.name}
        checked={
          (selectedWhitelist &&
            Array.isArray(selectedWhitelist) &&
            selectedWhitelist[0].bulletinWhitelistId === dataItem.bulletinWhitelistId) ||
          false
        }
        onChange={handleChange}
      />
    </td>
  );
}

export default memo(CellSelectWL);
