/* eslint-disable camelcase */
import {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  createContext,
  ReactElement,
  JSXElementConstructor,
  useContext,
} from 'react';
import type { RadioButtonChangeEvent } from '@progress/kendo-react-inputs';
import { useHistory } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';
import { getUserData } from '../services/local-storage-service';
import { setCurrentLocale, submitAddTask } from '../utils/helpers/add-task-helper';
import {
  fileFormatValue,
  kbRebootData,
  kbUpdateSrcData,
  reportTypeValue,
  taskTypesValue2,
} from '../utils/helpers/constants';
import type {
  IKbSelect,
  IPayloadAddTask,
  ISelectedCredential,
  ISelectTaskAddEdit,
  ITaskAdd,
  TCommonCredInTask,
  TIsDuplicate,
} from '../utils/helpers/types';
import { useHostModalContext } from './useHostModalContext';
import { useTaskContext } from './useTaskContext';
import localeNewTask from '../utils/i18n/taskLocale/newTask.json';
import type { TaskCreationRequest } from '../types/__generated/on-premise-solution/api/taskCreationRequest.v1';
import { useRegisterContext } from './useRegisterContext';
import { useUserModalContext } from './useUserModalContext';
import { useNetDeviceContext } from './useNetDeviceContext';
import type { TaskCreationResponse } from '../types/__generated/on-premise-solution/api/taskCreationResponse.v1';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import { createTask } from '../services/task-service';
import { useServerContext } from './useServerContext';
import { useSoftwareManagementContext } from './useSoftwareManagementContext';
import type { TaskActionDashboardResponse } from '../types/__generated/on-premise-solution/api/taskActionDashboardResponse.v1';
import { useGroupContext } from './useGroupContext';

const currentLocale = setCurrentLocale();

const TaskAddContext = createContext<ITaskAdd | null>(null);
export function TaskAddProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const {
    taskId,
    setOpenDialog,
    taskRepeatData,
    setTaskRepeatData,
    selectedBulletinIds,
    setSelectedBulletinIds,
    setBulletinId,
    setErrorMutation,
  } = useTaskContext();

  const queryClient = useQueryClient();

  const userName = getUserData();

  const history = useHistory();

  const {
    hostForm,
    hostFormTemp,
    setHostForm,
    setHostFormTemp,
    setSelectedPkgs,
    setSelectedPkgsWl,
    isPkgWlTask,
    selectedPkgs,
    missedKbs,
    setMissedKbs,
    setKbsInWl,
    isKbWlTask,
    setOpen,
    setWinSoftwareUpdateState,
    setWinSoftwareUpdateStateWl,
    winSoftwareUpdateState,
  } = useHostModalContext();

  const {
    registryForm,
    imageForm,
    setRegistryForm,
    registryFormTemp,
    setImageForm,
    setRegistryFormTemp,
  } = useRegisterContext();

  const {
    userTaskEmail,
    setUserTaskEmail,
    setUserTaskEmailTemp,
    setUserTaskInputEmail,
    userTaskInputEmail,
  } = useUserModalContext();

  const { setDeviceForm, setDeviceFormTemp, deviceForm, deviceFormTemp } = useNetDeviceContext();

  const { setServerForm, setLdapGrForm, ldapGrForm, serverForm } = useServerContext();

  const { setSelectedSoftwareForUpdate } = useSoftwareManagementContext();
  const { dynamicGroupForm, setDynamicGroupForm } = useGroupContext();

  const [taskName, setTaskName] = useState<string>(
    `${localeNewTask[currentLocale].form.defaultTaskName}_${new Date().toLocaleDateString()}_${
      userName?.email || ''
    }`,
  );

  const [taskType, setTaskType] = useState<ISelectTaskAddEdit>({
    value: taskTypesValue2[currentLocale][0],
  });

  const [taskTime, setTaskTime] = useState('now');

  const [reportType, setReportType] = useState<ISelectTaskAddEdit>({
    value: reportTypeValue[currentLocale][0],
  });

  const [isPdfFormat, setPdfFormat] = useState(false);

  const [isHtmlFormat, setHtmlFormat] = useState(false);

  const [fileFormat, setFileFormat] = useState<ISelectTaskAddEdit>({
    value: fileFormatValue[currentLocale][0],
  });

  const [datePicker, setDatePicker] = useState<Date | null>(null);

  const [fromDatePickerTaskAction, setFromDatePickerTaskAction] = useState<Date | null>(null);

  const [toDatePickerTaskAction, setToDatePickerTaskAction] = useState<Date | null>(null);

  const [isSoftwareManagement, setSoftwareManagement] = useState(false);

  const cronValue = useRef<string>('*/5 * * * 1');

  // task actions: ###########################################################
  const [taskInventorization, setTaskInventorization] = useState(false);

  const [taskAudit, setTaskAudit] = useState(false);

  const [runScanFirst, setRunScanFirst] = useState(false);

  const [taskWhiteList, setTaskWhiteList] = useState(true);

  const [taskWLImage, setTaskWLImage] = useState(true);

  const [taskAgentUpdate, setTaskAgentUpdate] = useState(false);

  const [taskPackageUpdate, setTaskPackageUpdate] = useState(false);

  const [taskWinSoftUpdate, setTaskWinSoftUpdate] = useState(false);

  const [taskKbUpdate, setTaskKbUpdate] = useState(false);

  const [taskGroupReport, setTaskGroupReport] = useState(false);

  const [taskSingleReport, setTaskSingleReport] = useState(false);

  const [taskAuditImage, setTaskAuditImage] = useState(false);

  const [taskSystemReboot, setTaskSystemReboot] = useState(false);

  // ##########################################################################

  const [cronInputValue, setCronInputValue] = useState<string>(cronValue.current);

  const [errorCron, setCronError] = useState('');

  const [correctCron, setCorrectCron] = useState(cronValue.current);

  const [taskSource, setTaskSource] = useState('');

  const [sourceSync, setSourceSync] = useState('ad');

  const [checkDuplicate, setDuplicate] = useState<TIsDuplicate>('doNotCheck');

  const [commonCredInTask, setCommonCredInTask] = useState<TCommonCredInTask[] | null>(null);

  const kbUpdateFrom = useMemo(
    () => [
      { text: localeNewTask[currentLocale].form.updateFromVal.default, id: 'default' },
      { text: localeNewTask[currentLocale].form.updateFromVal.global, id: 'global' },
      { text: localeNewTask[currentLocale].form.updateFromVal.wsus, id: 'wsus' },
    ],
    [],
  );

  const kbRebootValues = useMemo(
    () => [
      { text: localeNewTask[currentLocale].form.rebootBehaviorVal.never, id: 'never' },
      { text: localeNewTask[currentLocale].form.rebootBehaviorVal.canRequest, id: 'ifRequired' },
      { text: localeNewTask[currentLocale].form.rebootBehaviorVal.always, id: 'always' },
    ],
    [],
  );

  const [kbUpdateSrcVal, setKbUpdateSrcVal] = useState<IKbSelect>({
    text: kbUpdateSrcData[currentLocale][0].text,
    id: kbUpdateSrcData[currentLocale][0].id,
  });

  const [kbRebootVal, setKbRebootVal] = useState<IKbSelect>({
    text: kbRebootData[currentLocale][0].text,
    id: kbRebootData[currentLocale][0].id,
  });

  const [pkgRebootVal, setPkgRebootVal] = useState<IKbSelect>({
    text: kbRebootData[currentLocale][0].text,
    id: kbRebootData[currentLocale][0].id,
  });

  const [topN, setTopN] = useState<number>(1);

  const [syncTaskActionsdata, setSyncTaskActionsdata] = useState<
    TaskActionDashboardResponse[] | null
  >();

  const [taskSystemRebootValue, setTaskSystemRebootValue] = useState<number>(60);

  useEffect(() => {
    if (taskRepeatData) {
      setTaskAudit(!!(taskRepeatData && taskRepeatData.actionA === 'audit'));
      setTaskWhiteList(
        !!(taskRepeatData && taskRepeatData.actionWl === 'audit.useBulletinWhitelists'),
      );
      setTaskWLImage(
        !!(taskRepeatData && taskRepeatData.actionWl === 'audit.useBulletinWhitelists'),
      );
      setTaskInventorization(!!(taskRepeatData && taskRepeatData.actionI === 'inventorization'));
      setTaskAgentUpdate(!!(taskRepeatData && taskRepeatData.actionAgentU === 'agent_update'));
      setTaskPackageUpdate(
        !!(taskRepeatData && taskRepeatData.actionPackagetU === 'package_update'),
      );
      setTaskWinSoftUpdate(
        !!(
          taskRepeatData &&
          taskRepeatData.actionSoftwareManagement === 'windows_software_management'
        ),
      );
      setTaskKbUpdate(!!(taskRepeatData && taskRepeatData.actionKbInstall === 'kb_install'));
      setTaskGroupReport(!!(taskRepeatData && taskRepeatData.actionGR === 'group_report'));
      setTaskSingleReport(!!(taskRepeatData && taskRepeatData.actionCR === 'single_report'));
      setTaskAuditImage(!!(taskRepeatData && taskRepeatData.actionAImage === 'audit_image'));
      setTaskName(
        taskRepeatData
          ? `${taskRepeatData.taskName}`
          : `${
              localeNewTask[currentLocale].form.defaultTaskName
            }_${new Date().toLocaleDateString()}_${userName?.email}`,
      );
      setTaskSystemReboot(
        !!(taskRepeatData && taskRepeatData.taskSystemReboot === 'system_reboot'),
      );
    }

    if (taskRepeatData && taskRepeatData?.taskType === 'asset') {
      setTaskType({ value: taskTypesValue2[currentLocale][0] });
    } else if (taskRepeatData && taskRepeatData?.taskType === 'report') {
      setTaskType({ value: taskTypesValue2[currentLocale][1] });
    } else if (taskRepeatData && taskRepeatData?.taskType === 'asset_synchronize') {
      setTaskType({ value: taskTypesValue2[currentLocale][2] });
    }
  }, [taskRepeatData, userName?.email]);

  useEffect(() => {
    if (taskGroupReport && reportType.value.id === 'differential_audit') {
      setReportType({
        value: reportTypeValue[currentLocale][0],
      });
    }
  }, [reportType.value.id, taskGroupReport]);

  function handleDatePicker(data: Date | null): void {
    setDatePicker(data);
  }

  function handleFromTaskAction(data: Date | null): void {
    setFromDatePickerTaskAction(data);
  }

  function handleToTaskAction(data: Date | null): void {
    setToDatePickerTaskAction(data);
  }

  const handleExit = useCallback((): void => {
    setOpenDialog(false);
    setHostForm(null);
    setHostFormTemp(null);
    setTaskRepeatData(null);
    setUserTaskEmail(null);
    setUserTaskEmailTemp(null);
    setUserTaskInputEmail(null);
    setTaskTime('now');
    setTaskType({ value: taskTypesValue2[currentLocale][0] });
    setTaskAgentUpdate(false);
    setTaskPackageUpdate(false);
    setTaskWinSoftUpdate(false);
    setTaskKbUpdate(false);
    setImageForm(null);
    setRegistryForm(null);
    setRegistryFormTemp(null);
    setDeviceForm(null);
    setDeviceFormTemp(null);
    setDynamicGroupForm(null);
    if (isPkgWlTask) {
      setSelectedPkgsWl(null);
    }
    if (!isPkgWlTask) {
      setSelectedPkgs(null);
    }
    if (isKbWlTask) {
      setKbsInWl(null);
    }
    if (!isKbWlTask) {
      setMissedKbs(null);
    }
    setBulletinId('');
    setDatePicker(null);
    setFromDatePickerTaskAction(null);
    setToDatePickerTaskAction(null);
    setTaskInventorization(false);
    setTaskAudit(false);
    setTaskWhiteList(true);
    setTaskWLImage(true);
    setTaskGroupReport(false);
    setTaskSingleReport(false);
    setTaskAuditImage(false);
    setReportType({
      value: reportTypeValue[currentLocale][0],
    });
    setSelectedBulletinIds(null);
    setTaskName(
      `${localeNewTask[currentLocale].form.defaultTaskName}_${new Date().toLocaleDateString()}_${
        userName?.email || ''
      }`,
    );
    setTopN(1);
    setPdfFormat(false);
    setHtmlFormat(false);
    setKbRebootVal({
      text: kbRebootData[currentLocale][0].text,
      id: kbRebootData[currentLocale][0].id,
    });
    setPkgRebootVal({
      text: kbRebootData[currentLocale][0].text,
      id: kbRebootData[currentLocale][0].id,
    });
    setKbUpdateSrcVal({
      text: kbUpdateSrcData[currentLocale][0].text,
      id: kbUpdateSrcData[currentLocale][0].id,
    });
    if (!document.location.pathname.includes('/result')) {
      setTaskSource('');
      setServerForm(null);
      setLdapGrForm(null);
      setCommonCredInTask(null);
      setDuplicate('doNotCheck');
      setOpen(false);
    }
    setWinSoftwareUpdateState(null);
    setWinSoftwareUpdateStateWl(null);
    setSelectedSoftwareForUpdate({});
    setTaskSystemReboot(false);
    setTaskSystemRebootValue(60);
  }, [
    setOpenDialog,
    setHostForm,
    setHostFormTemp,
    setTaskRepeatData,
    setUserTaskEmail,
    setUserTaskEmailTemp,
    setUserTaskInputEmail,
    setImageForm,
    setRegistryForm,
    setRegistryFormTemp,
    setDeviceForm,
    setDeviceFormTemp,
    setDynamicGroupForm,
    isPkgWlTask,
    isKbWlTask,
    setBulletinId,
    setSelectedBulletinIds,
    userName?.email,
    setWinSoftwareUpdateState,
    setWinSoftwareUpdateStateWl,
    setSelectedSoftwareForUpdate,
    setSelectedPkgsWl,
    setSelectedPkgs,
    setKbsInWl,
    setMissedKbs,
    setServerForm,
    setLdapGrForm,
    setOpen,
  ]);

  const mutationNewTask = useMutation<TaskCreationResponse, ApiError, TaskCreationRequest>(
    (payload) => createTask(payload),
    {
      onSuccess: () => {
        setOpenDialog(false);
        handleExit();
        queryClient.invalidateQueries(['historyTasks']);
      },
      onError: (resp) => {
        setErrorMutation(resp.message);
      },
    },
  );

  const handleSubmit = useCallback((): void => {
    const addTaskData: IPayloadAddTask = {
      taskAudit,
      taskWhiteList,
      taskWLImage,
      taskInventorization,
      taskAgentUpdate,
      taskPackageUpdate,
      taskWinSoftUpdate,
      taskKbUpdate,
      taskGroupReport,
      taskSingleReport,
      taskAuditImage,
      reportType,
      fileFormat,
      isHtmlFormat,
      isPdfFormat,
      hostForm,
      imageForm,
      registryForm,
      deviceForm,
      taskTime,
      taskType,
      taskId: taskId || undefined,
      taskName,
      datePicker,
      fromDatePickerTaskAction,
      toDatePickerTaskAction,
      cronValue,
      userTaskEmail,
      userTaskInputEmail,
      selectedPkgs,
      missedKbs,
      bulletinIds: selectedBulletinIds || null,
      topN,
      kbUpdateSrcVal,
      kbRebootVal,
      pkgRebootVal,
      runScanFirst,
      ldapGrForm,
      checkDuplicate,
      serverForm,
      commonCredInTask,
      winSoftwareUpdateState,
      dynamicGroupForm,
      taskSystemReboot,
      taskSystemRebootValue,
    };
    const taskData = submitAddTask(addTaskData);

    console.log(taskData);

    mutationNewTask.mutateAsync(taskData as unknown as TaskCreationRequest);

    history.push('/lk/audit/history');
  }, [
    taskAudit,
    taskWhiteList,
    taskWLImage,
    taskInventorization,
    taskAgentUpdate,
    taskPackageUpdate,
    taskWinSoftUpdate,
    taskKbUpdate,
    taskGroupReport,
    taskSingleReport,
    taskAuditImage,
    reportType,
    fileFormat,
    isHtmlFormat,
    isPdfFormat,
    hostForm,
    imageForm,
    registryForm,
    deviceForm,
    taskTime,
    taskType,
    taskId,
    taskName,
    datePicker,
    fromDatePickerTaskAction,
    toDatePickerTaskAction,
    userTaskEmail,
    userTaskInputEmail,
    selectedPkgs,
    missedKbs,
    selectedBulletinIds,
    topN,
    kbUpdateSrcVal,
    kbRebootVal,
    pkgRebootVal,
    runScanFirst,
    ldapGrForm,
    checkDuplicate,
    serverForm,
    commonCredInTask,
    winSoftwareUpdateState,
    dynamicGroupForm,
    taskSystemReboot,
    taskSystemRebootValue,
    mutationNewTask,
    history,
  ]);

  const deleteAssetFromTask = useCallback(
    (assetId: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (hostForm && hostForm.length > 0) {
        setHostForm(hostForm?.filter((host) => host.assetId !== assetId));
      }
      if (hostFormTemp && hostFormTemp.length > 0) {
        setHostFormTemp(hostFormTemp?.filter((host) => host.assetId !== assetId));
      }
    },
    [hostForm, hostFormTemp, setHostForm, setHostFormTemp],
  );

  const deleteGrAssetFromTask = useCallback(
    (assetGroupId: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (hostForm && hostForm.length > 0) {
        setHostForm(hostForm?.filter((host) => host.assetGroupId !== assetGroupId));
      }
      if (hostFormTemp && hostFormTemp.length > 0) {
        setHostFormTemp(hostFormTemp?.filter((host) => host.assetGroupId !== assetGroupId));
      }
    },
    [hostForm, hostFormTemp, setHostForm, setHostFormTemp],
  );

  const deleteEmailFromTask = useCallback(
    (userId: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (userTaskEmail && userTaskEmail.length > 0) {
        setUserTaskEmail(userTaskEmail.filter((user) => user.userId !== userId));
      }
    },
    [setUserTaskEmail, userTaskEmail],
  );

  const deleteEmailInputTask = useCallback(
    (emailVal: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (userTaskInputEmail && userTaskInputEmail.length > 0) {
        setUserTaskInputEmail(userTaskInputEmail.filter((email) => email !== emailVal));
      }
    },
    [setUserTaskInputEmail, userTaskInputEmail],
  );

  const handleChange = useCallback(
    (e: RadioButtonChangeEvent) => {
      setTaskTime(e.value);
    },
    [setTaskTime],
  );

  const handleCheckDuplicate = useCallback(
    (e: RadioButtonChangeEvent) => {
      setDuplicate(e.value);
    },
    [setDuplicate],
  );

  const changeSourceOfSync = useCallback(
    (e: RadioButtonChangeEvent) => {
      setSourceSync(e.value);
    },
    [setSourceSync],
  );

  const selectTaskType = useCallback((e: ISelectTaskAddEdit): void => {
    if (e.value.id === 'host') {
      setTaskGroupReport(false);
      setTaskSingleReport(false);
      setTaskAuditImage(false);
    } else if (e.value.id === 'report') {
      setTaskInventorization(false);
      setTaskAudit(false);
      setTaskAgentUpdate(false);
      setTaskPackageUpdate(false);
      setTaskWinSoftUpdate(false);
      setTaskKbUpdate(false);
      setTaskAuditImage(false);
      setTaskSystemReboot(false);
    } else if (e.value.id === 'image') {
      setTaskAuditImage(true);
      setTaskGroupReport(false);
      setTaskSingleReport(false);
      setTaskInventorization(false);
      setTaskAudit(false);
      setTaskAgentUpdate(false);
      setTaskPackageUpdate(false);
      setTaskWinSoftUpdate(false);
      setTaskKbUpdate(false);
      setTaskSystemReboot(false);
    } else if (e.value.id === 'device') {
      setTaskGroupReport(false);
      setTaskSingleReport(false);
      setTaskAuditImage(false);
      setTaskAgentUpdate(false);
      setTaskPackageUpdate(false);
      setTaskWinSoftUpdate(false);
      setTaskKbUpdate(false);
    } else if (e.value.id === 'asset_synchronize') {
      setTaskAuditImage(false);
      setTaskGroupReport(false);
      setTaskSingleReport(false);
      setTaskInventorization(false);
      setTaskAudit(false);
      setTaskAgentUpdate(false);
      setTaskPackageUpdate(false);
      setTaskWinSoftUpdate(false);
      setTaskKbUpdate(false);
      setTaskSystemReboot(false);
    }
    setTaskType(e);
    setHostFormTemp(null);
    setHostForm(null);
    setDeviceForm(null);
    setImageForm(null);
    setRegistryForm(null);
  }, []);

  function normilizeCronValue(value: string, maxValue: number): string {
    const isSlash = value.includes('/');
    const [first, second] = value.split(isSlash ? '/' : '-');
    if (
      !isSlash &&
      second &&
      Number(first) <= maxValue &&
      Number(second) <= maxValue &&
      Number(second) < Number(first)
    )
      return second + (isSlash ? '/' : '-') + first;

    if (first === '*' && second === '0') return '*/1';

    if (
      (first === '*' && isSlash && Number(second) <= maxValue) ||
      (first !== '*' && Number(first) <= maxValue && Number(second) <= maxValue) ||
      (first !== '*' && Number(first) <= maxValue && !second)
    )
      return value;

    return '';
  }

  const changeCronValue = useCallback((): void => {
    const cronArr = cronInputValue.split(' ');

    if (cronArr.length === 5) {
      const checkCronValue = cronArr.map((c, i) => {
        const str = c
          .split(',')
          .filter((cv, ind) =>
            cv.match(
              /(^[0-9]{1,2}(-|\/){1}[0-9]{1,2}$)|(^\*(\/[0-9]{1,2})$)|(^\*$)|(^[0-9]{1,2}$)/,
            ),
          )
          .map((cv) => {
            const maxSizes = [59, 23, 31, 12, 7];
            if (cv === '') return '';

            return normilizeCronValue(cv, maxSizes[i]);
          })
          .filter((cv) => cv)
          .join(',');

        return str || '*';
      });
      const cronToString = checkCronValue.join(' ');
      setCorrectCron(cronToString);
      setCronInputValue(cronToString);
      cronValue.current = cronToString;
      setCronError('');
    }

    if (cronArr.length < 5 || cronArr.length > 5) {
      setCronError(localeNewTask[currentLocale].form.startTime.repeatError);
    }
  }, [cronValue, cronInputValue]);

  const addAssetToTaskScope = useCallback((): void => {
    setHostForm(hostFormTemp);
    setRegistryForm(registryFormTemp);
    setImageForm(imageForm);
    setDeviceForm(deviceFormTemp);
    setOpen(false);
  }, [
    deviceFormTemp,
    hostFormTemp,
    imageForm,
    registryFormTemp,
    setDeviceForm,
    setHostForm,
    setImageForm,
    setOpen,
    setRegistryForm,
  ]);

  const handleCommonCreds = useCallback(
    (credential: ISelectedCredential | undefined): void => {
      if (credential && commonCredInTask) {
        let neCommonCreds = [] as TCommonCredInTask[];

        if (commonCredInTask.find((c) => c.type === credential.type)) {
          neCommonCreds = commonCredInTask.map((cr) => {
            if (cr.type === credential.type) {
              return {
                ...cr,
                assetType: credential.assetType || '',
                credentialName: credential.credentialName || '',
                credentialId: credential.credentialId || '',
                type: credential.type,
              };
            }

            return cr;
          });
        } else {
          neCommonCreds = [
            ...commonCredInTask,
            {
              assetType: credential.assetType || '',
              credentialName: credential.credentialName || '',
              credentialId: credential.credentialId || '',
              type: credential.type || '',
            },
          ];
        }

        setCommonCredInTask(neCommonCreds);
      } else if (credential && !commonCredInTask) {
        const data = [
          {
            assetType: credential.assetType,
            credentialName: credential.credentialName,
            credentialId: credential.credentialId,
            type: credential.type,
          },
        ] as TCommonCredInTask[];
        setCommonCredInTask([...data]);
      }
    },
    [commonCredInTask],
  );

  const value = useMemo(
    () => ({
      datePicker,
      fromDatePickerTaskAction,
      toDatePickerTaskAction,
      cronValue,
      taskName,
      setTaskName,
      taskType,
      reportType,
      setReportType,
      fileFormat,
      setFileFormat,
      taskInventorization,
      setTaskInventorization,
      taskAudit,
      setTaskAudit,
      taskAgentUpdate,
      setTaskAgentUpdate,
      taskPackageUpdate,
      setTaskPackageUpdate,
      taskWinSoftUpdate,
      setTaskWinSoftUpdate,
      taskKbUpdate,
      setTaskKbUpdate,
      taskGroupReport,
      setTaskGroupReport,
      taskSingleReport,
      setTaskSingleReport,
      taskAuditImage,
      setTaskAuditImage,
      handleSubmit,
      deleteAssetFromTask,
      deleteGrAssetFromTask,
      handleExit,
      handleChange,
      taskTime,
      handleDatePicker,
      selectTaskType,
      cronInputValue,
      setCronInputValue,
      errorCron,
      correctCron,
      changeCronValue,
      deleteEmailFromTask,
      deleteEmailInputTask,
      topN,
      setTopN,
      taskWhiteList,
      setTaskWhiteList,
      taskWLImage,
      setTaskWLImage,
      isHtmlFormat,
      setHtmlFormat,
      isPdfFormat,
      setPdfFormat,
      handleFromTaskAction,
      handleToTaskAction,
      kbUpdateFrom,
      kbUpdateSrcVal,
      setKbUpdateSrcVal,
      kbRebootValues,
      kbRebootVal,
      setKbRebootVal,
      pkgRebootVal,
      setPkgRebootVal,
      taskSource,
      setTaskSource,
      addAssetToTaskScope,
      runScanFirst,
      setRunScanFirst,
      sourceSync,
      setSourceSync,
      changeSourceOfSync,
      commonCredInTask,
      setCommonCredInTask,
      handleCommonCreds,
      checkDuplicate,
      setDuplicate,
      handleCheckDuplicate,
      syncTaskActionsdata,
      setSyncTaskActionsdata,
      isSoftwareManagement,
      setSoftwareManagement,
      taskSystemReboot,
      setTaskSystemReboot,
      taskSystemRebootValue,
      setTaskSystemRebootValue,
    }),
    [
      datePicker,
      fromDatePickerTaskAction,
      toDatePickerTaskAction,
      taskName,
      taskType,
      reportType,
      fileFormat,
      taskInventorization,
      taskAudit,
      taskAgentUpdate,
      taskPackageUpdate,
      taskWinSoftUpdate,
      taskKbUpdate,
      taskGroupReport,
      taskSingleReport,
      taskAuditImage,
      handleSubmit,
      deleteAssetFromTask,
      deleteGrAssetFromTask,
      handleExit,
      handleChange,
      taskTime,
      selectTaskType,
      cronInputValue,
      errorCron,
      correctCron,
      changeCronValue,
      deleteEmailFromTask,
      deleteEmailInputTask,
      topN,
      taskWhiteList,
      taskWLImage,
      isHtmlFormat,
      isPdfFormat,
      kbUpdateFrom,
      kbUpdateSrcVal,
      kbRebootValues,
      kbRebootVal,
      pkgRebootVal,
      taskSource,
      addAssetToTaskScope,
      runScanFirst,
      sourceSync,
      changeSourceOfSync,
      commonCredInTask,
      handleCommonCreds,
      checkDuplicate,
      handleCheckDuplicate,
      syncTaskActionsdata,
      isSoftwareManagement,
      taskSystemReboot,
      taskSystemRebootValue,
    ],
  );

  return <TaskAddContext.Provider value={value}>{children}</TaskAddContext.Provider>;
}

export const useTaskAddContext = (): ITaskAdd => {
  const context = useContext(TaskAddContext);
  if (context === null) {
    throw new Error('useAddTaskContext must be used inside the WebSocketContext.Provider.');
  }

  return context;
};
