import React, { useState } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import localeTaskDescription from '../../../../utils/i18n/taskLocale/taskDescription.json';
import '../TaskDescription.module.scss';
import { Results } from './Results';
import { Artifacts } from './Artifacts';
import { Logs } from './Logs';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTaskDescription;

export function TabsTaskDescription(): React.ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const handleSelect = (e: TabStripSelectEventArguments): void => {
    setSelected(e.selected);
  };

  return (
    <TabStrip selected={selected} onSelect={handleSelect}>
      <TabStripTab title={localeTaskDescription[currentLocale].tabs.results}>
        <Results />
      </TabStripTab>
      <TabStripTab title={localeTaskDescription[currentLocale].tabs.artifacts}>
        <Artifacts />
      </TabStripTab>
      <TabStripTab title={localeTaskDescription[currentLocale].tabs.executionLog}>
        <Logs />
      </TabStripTab>
    </TabStrip>
  );
}
