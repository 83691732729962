/* eslint-disable no-nested-ternary */
import {
  createContext,
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { useHistory } from 'react-router';
import { useHostModalContext } from './useHostModalContext';
import { useNetDeviceContext } from './useNetDeviceContext';
import { useRegisterContext } from './useRegisterContext';
import { useAccountModalContext } from './useAccountModalContext';
import { getAccountId, getUserData } from '../services/local-storage-service';
import localeUserList from '../utils/i18n/userLocales/userList.json';
import type {
  BulletinWhitelistCreationRequest,
  WhitelistedBulletin,
} from '../types/__generated/on-premise-solution/api/bulletinWhitelistCreationRequest.v1';
import {
  createWhiteList,
  deleteWhiteList,
  getWhiteLIstId,
  updateWhiteList,
} from '../services/settings-service';
import type { BulletinWhitelistCreationResponse } from '../types/__generated/on-premise-solution/api/bulletinWhitelistCreationResponse.v1';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import {
  prepareAssetType,
  prepareWhiteListSubmit,
  prepareWhitelistUpdate,
  usedEditAssetType,
} from '../utils/helpers/settings-helpers';
import type { BulletinWhitelistUpdateResponse } from '../types/__generated/on-premise-solution/api/bulletinWhitelistUpdateResponse.v1';
import type { BulletinWhitelistUpdateRequest } from '../types/__generated/on-premise-solution/api/bulletinWhitelistUpdateRequest.v1';
import type {
  IAssetsAlreadyInWl,
  IWhiteListContext,
  TExtendedBulletinWhitelist,
} from '../utils/helpers/types';
import type { BulletinWhitelistDeletionResponse } from '../types/__generated/on-premise-solution/api/bulletinWhitelistDeletionResponse.v1';
import type { BulletinWhitelistDashboardResponse } from '../types/__generated/on-premise-solution/api/bulletinWhitelistDashboardResponse.v1';
import { useAssetCreatePropContext } from './useAssetCreatePropContext';
import { useGroupContext } from './useGroupContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUserList;

const WhiteListContext = createContext<IWhiteListContext | null>(null);

export function WhiteListProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const currentUser = getUserData();

  const history = useHistory();

  const currentAccountId = getAccountId();

  const queryClient = useQueryClient();

  const [isWhiteListForm, setWhiteListForm] = useState<boolean>(false);

  const [isRelatedWhiteListModal, setRelatedWhiteListModal] = useState<boolean>(false);
  const relatedWlIds = useRef<string[]>([]);

  const [name, setName] = useState<string>('');

  const [description, setDescription] = useState<string | null>('');

  const [bulletinForm, setBulletinForm] = useState<WhitelistedBulletin[] | null>(null);

  const [bulletinFormTemp, setBulletinFormTemp] = useState<WhitelistedBulletin[] | null>(null);

  const [whiteListStatus, setWhiteListStatus] = useState<{ text: string; val: boolean }>({
    text: localeUserList[currentLocale].active,
    val: false,
  });

  const [isAddBulletin, setAddBulletin] = useState(false);

  const [whitelistUpdate, setWhitelistUpdate] = useState<TExtendedBulletinWhitelist | null>(null);

  const [initialState, setInitialState] = useState<TExtendedBulletinWhitelist | null>(null);

  const [errorMutation, setErrorMutation] = useState('');

  const [assetType, setAssetType] = useState<IWhiteListContext['assetType']>({
    host: false,
    image: false,
    router: false,
    switch: false,
    firewall: false,
    hypervisor: false,
    all: false,
  });

  const [onSubmitVulnSuccess, setOnSubmitVulnSuccess] = useState(false);

  const [selectedWhitelist, setSelectedWhitelist] = useState<
    | {
        name: string;
        bulletinWhitelistId: string;
      }[]
    | null
  >(null);

  const whiteListStatusValues = useMemo(
    () => [
      { text: currentLocale === 'ru-RU' ? 'Активный' : 'Active', val: false },
      { text: currentLocale === 'ru-RU' ? 'Не активный' : 'Not active', val: true },
    ],
    [],
  );

  const [assetsAlreadyInWl, setAssetsAlreadyInWl] = useState<IAssetsAlreadyInWl[] | null>(null);

  const { accountId, setAccountId } = useAccountModalContext();

  const { hostForm, setHostForm, setHostFormTemp, missedKbs, setMissedKbs } = useHostModalContext();

  const { deviceForm, setDeviceForm, setDeviceFormTemp } = useNetDeviceContext();

  const { imageForm, setImageForm } = useRegisterContext();

  const { setAddVulnToWLForm, selectedVulns, setSelectedVulns } = useAssetCreatePropContext();

  const { setDynamicGroupForm, dynamicGroupForm } = useGroupContext();

  useEffect(() => {
    if (whitelistUpdate) {
      setName(whitelistUpdate.name);
      setWhiteListStatus(
        whitelistUpdate.isDisabled
          ? { text: localeUserList[currentLocale].notActive, val: true }
          : { text: localeUserList[currentLocale].active, val: false },
      );
      // setAccountId(
      //   whitelistUpdate
      //     ? { id: whitelistUpdate.account.accountId, name: whitelistUpdate.account.name }
      //     : {
      //         id: '000',
      //         name: localeUserList[currentLocale].modal.placeholderAccountName,
      //       },
      // );
      setDescription(whitelistUpdate.description);
      setInitialState(whitelistUpdate);
    }
  }, [setAccountId, whitelistUpdate]);

  const cancelWhiteListForm = useCallback((): void => {
    setWhiteListForm(false);
    setName('');
    setDescription('');
    setAccountId({
      id: '000',
      name: localeUserList[currentLocale].modal.placeholderAccountId,
    });
    setBulletinForm(null);
    setBulletinFormTemp(null);
    setDeviceForm(null);
    setDeviceFormTemp(null);
    setHostForm(null);
    setHostFormTemp(null);
    setImageForm(null);
    setWhitelistUpdate(null);
    setAssetType({
      host: false,
      image: false,
      router: false,
      switch: false,
      firewall: false,
      hypervisor: false,
      all: false,
    });
    setSelectedVulns(null);
    setMissedKbs(null);
    setDynamicGroupForm(null);
  }, [
    setAccountId,
    setDeviceForm,
    setDeviceFormTemp,
    setDynamicGroupForm,
    setHostForm,
    setHostFormTemp,
    setImageForm,
    setMissedKbs,
    setSelectedVulns,
  ]);

  const closeAddVulnToWLForm = useCallback((): void => {
    setSelectedVulns(null);
    setAddVulnToWLForm(false);
    setWhiteListForm(false);
    setMissedKbs(null);
  }, [setAddVulnToWLForm, setMissedKbs, setSelectedVulns]);

  const mutationNewWhiteList = useMutation<
    BulletinWhitelistCreationResponse,
    ApiError,
    BulletinWhitelistCreationRequest
  >((payload) => createWhiteList(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('WhiteList');
      cancelWhiteListForm();
      setOnSubmitVulnSuccess(true);
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const mutationEditWhiteList = useMutation<
    BulletinWhitelistUpdateResponse,
    ApiError,
    { id: string; payload: BulletinWhitelistUpdateRequest }
  >(({ id, payload }) => updateWhiteList(id, payload), {
    onSuccess: () => {
      if (selectedVulns || missedKbs) {
        setOnSubmitVulnSuccess(true);
      }
      queryClient.invalidateQueries('WhiteList');
      queryClient.invalidateQueries('bulletinWhitelistId');
      cancelWhiteListForm();
      closeAddVulnToWLForm();
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const mutationDeleteWhiteList = useMutation<
    BulletinWhitelistDeletionResponse | null,
    ApiError,
    BulletinWhitelistDeletionResponse['bulletinWhitelistId']
  >((payload) => deleteWhiteList(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('WhiteList');
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const onSubmit = useCallback((): void => {
    if (!whitelistUpdate) {
      const { assets, assetGroups, assetDynamicGroups } = prepareWhiteListSubmit(
        hostForm,
        deviceForm,
        imageForm,
        dynamicGroupForm,
      );

      const onSubmitData = {
        name,
        accountId:
          currentUser && currentUser.role === 'super_admin' ? accountId.id : currentAccountId || '',
        description: description || null,
        scope: {
          assets,
          assetGroups,
          assetTypes: assetType && prepareAssetType(assetType),
          assetDynamicGroups:
            assetDynamicGroups && assetDynamicGroups.length > 0 ? assetDynamicGroups : null,
        },
        bulletins: bulletinForm ? [...bulletinForm] : undefined,
        isDisabled: whiteListStatus.val,
      } as BulletinWhitelistCreationRequest;
      setSelectedWhitelist([
        {
          name,
          bulletinWhitelistId: '',
        },
      ]);
      mutationNewWhiteList.mutate({ ...onSubmitData });
    } else {
      const { assets, assetGroups, assetDynamicGroups } = prepareWhiteListSubmit(
        hostForm,
        deviceForm,
        imageForm,
        dynamicGroupForm,
      );

      const onSubmitData = {
        name: name !== initialState?.name ? name : undefined,
        description:
          description !== initialState?.description && description && description.length > 0
            ? description
            : description === initialState?.description
            ? undefined
            : null,
        scope: {
          assets: assets && assets.length > 0 ? assets : null,
          assetTypes: assetType && prepareAssetType(assetType),
          assetGroups: assetGroups && assetGroups.length > 0 ? assetGroups : null,
          assetDynamicGroups:
            assetDynamicGroups && assetDynamicGroups.length > 0 ? assetDynamicGroups : null,
        },
        bulletins:
          JSON.stringify(bulletinForm) !== JSON.stringify(initialState?.bulletins)
            ? bulletinForm?.map((b) => ({
                addedAt: b.addedAt,
                addedBy: {
                  id: b.addedBy.source === 'user' && b.addedBy.id,
                  source: 'user',
                },
                bulletinId: b.bulletinId,
              }))
            : undefined,
        isDisabled:
          whiteListStatus.val !== initialState?.isDisabled ? whiteListStatus.val : undefined,
      } as BulletinWhitelistUpdateRequest;
      mutationEditWhiteList.mutateAsync({
        id: whitelistUpdate.bulletinWhitelistId,
        payload: onSubmitData,
      });
    }
  }, [
    accountId.id,
    assetType,
    bulletinForm,
    currentAccountId,
    currentUser,
    description,
    deviceForm,
    dynamicGroupForm,
    hostForm,
    imageForm,
    initialState?.bulletins,
    initialState?.description,
    initialState?.isDisabled,
    initialState?.name,
    mutationEditWhiteList,
    mutationNewWhiteList,
    name,
    whiteListStatus.val,
    whitelistUpdate,
  ]);

  const delBulletinFromMutableBlock = useCallback(
    (
      bulletin: string | undefined,
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ): void => {
      event.stopPropagation();
      if (bulletinForm && bulletinForm.length > 0) {
        setBulletinForm(bulletinForm?.filter((b) => b.bulletinId !== bulletin));
      }
      if (bulletinFormTemp && bulletinFormTemp.length > 0) {
        setBulletinFormTemp(bulletinFormTemp?.filter((b) => b.bulletinId !== bulletin));
      }
    },
    [bulletinForm, bulletinFormTemp],
  );

  const addSelectedBulletins = useCallback((): void => {
    setAddBulletin(false);
    setBulletinForm(bulletinFormTemp);
  }, [bulletinFormTemp]);

  const changeDropDownStatus = useCallback(
    (event: DropDownListChangeEvent): void => {
      if (event.target.value === localeUserList[currentLocale].active) {
        setWhiteListStatus({ text: localeUserList[currentLocale].active, val: false });
      } else {
        setWhiteListStatus({ text: localeUserList[currentLocale].notActive, val: true });
      }
    },
    [setWhiteListStatus],
  );

  const editWhiteList = useCallback(
    (val: BulletinWhitelistDashboardResponse): void => {
      prepareWhitelistUpdate(
        val,
        setHostForm,
        setDeviceForm,
        setImageForm,
        setBulletinForm,
        setDynamicGroupForm,
      );
      setWhitelistUpdate(val);
      setAssetType(usedEditAssetType(val.scope.assetTypes));
    },
    [setDeviceForm, setDynamicGroupForm, setHostForm, setImageForm],
  );

  const setStatusWhiteList = useCallback(
    (val: boolean, id: string) => {
      const payload = {
        isDisabled: !val,
      };
      mutationEditWhiteList.mutateAsync({ id, payload });
    },
    [mutationEditWhiteList],
  );

  const handleNewWl = useCallback((): void => {
    setAddVulnToWLForm(false);
    setWhiteListForm(true);
    const bulletins = [] as WhitelistedBulletin[];
    if (selectedVulns) {
      selectedVulns.forEach((v) => {
        const t = {
          bulletinId: v.vulnId,
          addedBy: {
            source: 'user' as const,
            id: currentUser?.id || '',
          },
          addedAt: new Date().toISOString(),
        };
        bulletins.push(t);
      });
    }

    if (bulletins && bulletins.length > 0) setBulletinForm(bulletins);
    if (
      selectedVulns &&
      selectedVulns.length > 0 &&
      selectedVulns[0].assetId &&
      selectedVulns[0].hostname
    ) {
      if (document.location.pathname.includes('assets/hosts'))
        setHostForm([{ assetId: selectedVulns[0].assetId, hostname: selectedVulns[0].hostname }]);
      if (document.location.pathname.includes('assets/devices'))
        setDeviceForm([{ assetId: selectedVulns[0].assetId, hostname: selectedVulns[0].hostname }]);
    }
    if (
      selectedVulns &&
      selectedVulns.length > 0 &&
      selectedVulns[0].imageNames &&
      selectedVulns[0].assetId &&
      document.location.pathname.includes('assets/images')
    )
      setImageForm([
        {
          imageNames: selectedVulns[0].imageNames || '',
          assetId: selectedVulns[0].assetId || '',
        },
      ]);
  }, [
    currentUser?.id,
    selectedVulns,
    setAddVulnToWLForm,
    setDeviceForm,
    setHostForm,
    setImageForm,
  ]);

  useQuery<BulletinWhitelistDashboardResponse, ApiError>(
    ['bulletinWhitelistId', (selectedWhitelist && selectedWhitelist[0].bulletinWhitelistId) || ''],
    () => getWhiteLIstId((selectedWhitelist && selectedWhitelist[0].bulletinWhitelistId) || ''),
    {
      onSuccess: (response) => {
        const assetsInScope = response.scope.assets?.map((a) => ({
          assetId: a.assetId,
          addedBy: {
            source: a.addedBy.source,
            id: a.addedBy.source === 'user' ? a.addedBy.id : '',
          },
          addedAt: a.addedAt,
        }));
        if (assetsInScope) setAssetsAlreadyInWl(assetsInScope);
      },
      enabled: !!(selectedWhitelist && selectedWhitelist[0].bulletinWhitelistId),
    },
  );

  const onSubmitVuln = useCallback((): void => {
    const bulletins = [] as WhitelistedBulletin[];

    const { assets, assetGroups, assetDynamicGroups } = prepareWhiteListSubmit(
      hostForm,
      deviceForm,
      imageForm,
      dynamicGroupForm,
    );

    if (selectedVulns && !missedKbs) {
      selectedVulns.forEach((v) => {
        const b = {
          bulletinId: v.vulnId,
          addedBy: {
            source: 'user' as const,
            id: currentUser?.id || '',
          },
          addedAt: new Date().toISOString(),
        };
        bulletins.push(b);
      });
    }

    if (!selectedVulns && missedKbs) {
      missedKbs.forEach((kb) => {
        const b = {
          bulletinId: kb.id,
          addedBy: {
            source: 'user' as const,
            id: currentUser?.id || '',
          },
          addedAt: new Date().toISOString(),
        };
        bulletins.push(b);
      });
    }

    mutationEditWhiteList.mutateAsync({
      id: selectedWhitelist ? selectedWhitelist[0].bulletinWhitelistId : '',
      payload: {
        bulletinsToAdd: bulletins,
        scope: {
          assets: [...(assetsAlreadyInWl || []), ...(assets || [])],
          assetGroups: assetGroups && assetGroups.length > 0 ? assetGroups : null,
          assetDynamicGroups:
            assetDynamicGroups && assetDynamicGroups.length > 0 ? assetDynamicGroups : null,
          assetTypes: (assetType && prepareAssetType(assetType)) || null,
        },
      },
    });
    closeAddVulnToWLForm();
  }, [
    assetType,
    assetsAlreadyInWl,
    closeAddVulnToWLForm,
    currentUser?.id,
    deviceForm,
    dynamicGroupForm,
    hostForm,
    imageForm,
    missedKbs,
    mutationEditWhiteList,
    selectedVulns,
    selectedWhitelist,
  ]);

  const value = useMemo(
    () => ({
      isWhiteListForm,
      setWhiteListForm,
      relatedWlIds,
      isRelatedWhiteListModal,
      setRelatedWhiteListModal,
      name,
      setName,
      description,
      setDescription,
      onSubmit,
      cancelWhiteListForm,
      bulletinForm,
      setBulletinForm,
      isAddBulletin,
      setAddBulletin,
      delBulletinFromMutableBlock,
      bulletinFormTemp,
      setBulletinFormTemp,
      addSelectedBulletins,
      changeDropDownStatus,
      whiteListStatus,
      whiteListStatusValues,
      mutationEditWhiteList,
      editWhiteList,
      whitelistUpdate,
      errorMutation,
      setErrorMutation,
      setStatusWhiteList,
      mutationDeleteWhiteList,
      assetType,
      setAssetType,
      selectedWhitelist,
      setSelectedWhitelist,
      handleNewWl,
      onSubmitVuln,
      closeAddVulnToWLForm,
      onSubmitVulnSuccess,
      setOnSubmitVulnSuccess,
    }),
    [
      isWhiteListForm,
      relatedWlIds,
      isRelatedWhiteListModal,
      name,
      description,
      onSubmit,
      cancelWhiteListForm,
      bulletinForm,
      isAddBulletin,
      delBulletinFromMutableBlock,
      bulletinFormTemp,
      addSelectedBulletins,
      changeDropDownStatus,
      whiteListStatus,
      whiteListStatusValues,
      mutationEditWhiteList,
      editWhiteList,
      whitelistUpdate,
      errorMutation,
      setStatusWhiteList,
      mutationDeleteWhiteList,
      assetType,
      selectedWhitelist,
      handleNewWl,
      onSubmitVuln,
      closeAddVulnToWLForm,
      onSubmitVulnSuccess,
    ],
  );

  return <WhiteListContext.Provider value={value}>{children}</WhiteListContext.Provider>;
}

export const useWhiteListContext = (): IWhiteListContext => {
  const context = useContext(WhiteListContext);
  if (context === null) {
    throw new Error('useWhiteListContext must be used inside the WebSocketContext.Provider.');
  }

  return context;
};
