import { useCallback, useState } from 'react';
import type { TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import { TabsGroups } from '../../features/ContainerOfGroups/TabsGroups';

export function AddGroupsForTask(): React.ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback((e: TabStripSelectEventArguments): void => {
    setSelected(e.selected);
  }, []);

  return (
    <div>
      <TabsGroups selected={selected} handleSelect={handleSelect} taskElement="AddGroupsForTask" />
    </div>
  );
}
