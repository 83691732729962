import { useEffect } from 'react';
import {
  Notification as KendoNotification,
  NotificationGroup,
} from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import './NotificationComponent.scss';
import { INotification, useNotificationContext } from '../../../hooks/useNotificationContext';

export function NotificationComponent(): React.ReactElement {
  const { notifications, deleteNotification } = useNotificationContext();

  useEffect(() => {
    const notificationsForDel = notifications.filter(
      (n) => n.type === 'info' || n.type === 'success',
    );
    const timeout = setTimeout(() => {
      notificationsForDel.forEach((n) => deleteNotification(n));
    }, 5000);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [notifications, deleteNotification]);

  const handleNotification = (m: INotification): void => {
    deleteNotification(m);
  };

  return (
    <NotificationGroup
      style={{
        right: 10,
        bottom: 50,
        alignItems: 'flex-start',
        flexWrap: 'wrap-reverse',
      }}
    >
      <Fade>
        {notifications &&
          notifications.map((n) => (
            <KendoNotification
              key={n.id}
              type={{
                style: n.type,
              }}
              style={{ padding: 16 }}
              closable
              onClose={(): void => handleNotification(n)}
            >
              <span>{n.message}</span>
            </KendoNotification>
          ))}
      </Fade>
    </NotificationGroup>
  );
}
