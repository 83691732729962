/* eslint-disable @typescript-eslint/dot-notation */
import type { GridCellProps } from '@progress/kendo-react-grid';
import type { TaskHostDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/tasksDashboardResponse.v1';
import type { TaskExecutionDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import { TaskType } from '../common/TaskType';

export function Actions(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { actions }: TaskHostDashboardResponse | TaskExecutionDashboardResponse = dataItem;

  return (
    <td>
      <div className="task__actions">
        {actions && 'audit' in actions && <TaskType title type="audit" />}
        {actions && 'audit_image' in actions && <TaskType title type="audit_image" />}
        {actions && 'inventorization' in actions && <TaskType title type="inventorization" />}
        {actions && 'agent_update' in actions && <TaskType title type="agent_update" />}
        {actions && 'package_update' in actions && <TaskType title type="package_update" />}
        {actions && 'windows_software_management' in actions && (
          <TaskType title type="windows_software_management" />
        )}
        {actions && 'group_report' in actions && <TaskType title type="group_report" />}
        {actions && 'notification' in actions && <TaskType title type="notification" />}
        {actions && 'single_report' in actions && <TaskType title type="single_report" />}
        {actions && 'kb_install' in actions && <TaskType title type="kb_install" />}
        {actions && 'asset_ldap_synchronize' in actions && (
          <TaskType title type="asset_ldap_synchronize" />
        )}
        {actions && 'system_reboot' in actions && <TaskType title type="system_reboot" />}
      </div>
    </td>
  );
}
