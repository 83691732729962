import React, { useCallback, useEffect, useState } from 'react';
import { loadMessages } from '@progress/kendo-react-intl';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { ErrorBoundary } from 'react-error-boundary';
import { useHistory } from 'react-router';
import { Dialog } from '@progress/kendo-react-dialogs';
import localeCommonTable from '../../../utils/i18n/commonLocales/table.json';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { BoundaryErrorComponent } from '../BoundaryErrorComponent';
import { Button } from '../baseElements/Button';
import { getUserData } from '../../../services/local-storage-service';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import { HostListFormTab } from './HostListFormTab';
import { RegistryListFormTab } from '../../features/Containers/Registers/RegistryListFormTab';
import { DeviceListFormScope } from '../../features/Devices/DeviceListFormScope';
import styles from './Form.module.scss';
import { ImageListFormTab } from '../../features/Settings/ApiKeys/form/ImageListFormTab';
import { useTaskAddContext } from '../../../hooks/useTaskAddContext';
import { useNetDeviceContext } from '../../../hooks/useNetDeviceContext';
import { useRegisterContext } from '../../../hooks/useRegisterContext';
import { AddGroupsForTask } from './AddGroupsForTask';
import { useGroupContext } from '../../../hooks/useGroupContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

loadMessages(localeCommonTable[currentLocale], currentLocale);

export function TaskScopeArea(): React.ReactElement {
  const currentUser = getUserData();

  const [countOfSelectedAsset, setCountOfSelectedAsset] = useState(0);

  const [countOfGroup, setCountOfGroup] = useState(0);

  const { addAssetToTaskScope, taskType, taskWinSoftUpdate, taskSystemReboot } =
    useTaskAddContext();

  const { hostForm, hostFormTemp, setOpen, setHostFormTemp } = useHostModalContext();

  const { deviceForm, deviceFormTemp, setDeviceFormTemp } = useNetDeviceContext();

  const { registryForm, registryFormTemp, imageForm, setRegistryFormTemp, setImageForm } =
    useRegisterContext();

  const { dynamicGroupForm, setDynamicGroupForm } = useGroupContext();

  const history = useHistory();

  const [selected, setSelected] = useState<number>(
    history.location.pathname.includes('registries') ? 1 : 0,
  );

  const handleSelect = useCallback((e: TabStripSelectEventArguments): void => {
    if (e.selected === 0) {
      setSelected(e.selected);
    }
    if (e.selected === 1) {
      setSelected(e.selected);
    }
    if (e.selected === 2) {
      setSelected(e.selected);
    }
    if (e.selected === 3) {
      setSelected(e.selected);
    }
    if (e.selected === 4) {
      setSelected(e.selected);
    }
  }, []);

  const getTotalAssets = (): number => {
    let count = 0;

    if (hostFormTemp && hostFormTemp.length > 0) count = hostFormTemp.length;
    if (deviceFormTemp && deviceFormTemp.length > 0) count += deviceFormTemp.length;
    if (registryFormTemp && registryFormTemp.length > 0) count += registryFormTemp.length;
    if (imageForm && imageForm.length > 0) count += imageForm.length;
    if (dynamicGroupForm && dynamicGroupForm.length > 0) count += dynamicGroupForm.length;

    return count;
  };

  const exitScopeArea = (): void => {
    setOpen(false);
    setRegistryFormTemp(null);
    setImageForm(null);
    setHostFormTemp(null);
    setDeviceFormTemp(null);
    setDynamicGroupForm(null);
  };

  useEffect(() => {
    let value = 0;
    if (hostFormTemp && hostFormTemp.filter((gr) => !gr.assetId).length) {
      value += hostFormTemp.filter((gr) => !gr.assetId).length;
    }

    if (hostFormTemp && hostFormTemp.filter((g) => g.assetGroupId).length === 0) {
      setCountOfGroup(0);
    }

    if (dynamicGroupForm && dynamicGroupForm.length > 0) {
      value += dynamicGroupForm.length;
    }

    if (value > 0) setCountOfGroup(value);
  }, [hostFormTemp, dynamicGroupForm]);

  useEffect(() => {
    setCountOfSelectedAsset(getTotalAssets());
  }, [hostFormTemp, deviceFormTemp, registryFormTemp, imageForm, dynamicGroupForm]);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <Dialog
        title={localeNewTask[currentLocale].form.taskScope.title}
        onClose={exitScopeArea}
        style={{ maxWidth: currentUser?.role === 'super_admin' ? '1640px' : '' }}
        className={styles.taskScopeWrap}
      >
        <div className="k-form">
          <div className="form-content select-asset">
            <TabStrip selected={selected} onSelect={handleSelect}>
              {taskType.value.id !== 'asset_synchronize' && (
                <TabStripTab
                  title={`${localeNewTask[currentLocale].form.taskScope.tabHost} ${
                    hostFormTemp &&
                    hostFormTemp.filter((h) => !h.assetGroupId).length &&
                    hostFormTemp.filter((h) => !h.assetGroupId).length > 0
                      ? `(${hostFormTemp.filter((h) => !h.assetGroupId).length})`
                      : ''
                  }`}
                >
                  <HostListFormTab osType={taskWinSoftUpdate ? 'windows' : ''} />
                </TabStripTab>
              )}
              {taskType.value.id !== 'asset_synchronize' &&
                !taskWinSoftUpdate &&
                !taskSystemReboot && (
                  <TabStripTab
                    title={`${localeNewTask[currentLocale].form.taskScope.tabRegistries} ${
                      registryFormTemp && registryFormTemp.length > 0
                        ? `(${registryFormTemp.length})`
                        : ''
                    }`}
                  >
                    <RegistryListFormTab />
                  </TabStripTab>
                )}
              {taskType.value.id !== 'asset_synchronize' &&
                !taskWinSoftUpdate &&
                !taskSystemReboot && (
                  <TabStripTab
                    title={`${localeNewTask[currentLocale].form.taskScope.tabContainerImage} ${
                      imageForm && imageForm.length > 0 ? `(${imageForm.length})` : ''
                    }`}
                  >
                    <ImageListFormTab />
                  </TabStripTab>
                )}
              {taskType.value.id !== 'asset_synchronize' &&
                !taskWinSoftUpdate &&
                !taskSystemReboot && (
                  <TabStripTab
                    title={`${localeNewTask[currentLocale].form.taskScope.tabNetworkDevice} ${
                      deviceFormTemp && deviceFormTemp.length > 0
                        ? `(${deviceFormTemp.length})`
                        : ''
                    }`}
                  >
                    <DeviceListFormScope />
                  </TabStripTab>
                )}
              <TabStripTab
                title={`${localeNewTask[currentLocale].form.taskScope.tabGroups} ${
                  countOfGroup > 0 ? `(${countOfGroup})` : ''
                }`}
              >
                <AddGroupsForTask />
              </TabStripTab>
            </TabStrip>
          </div>
          <div className="k-form-buttons">
            <Button
              type="submit"
              disabled={
                ((!hostFormTemp && hostForm?.length === 0) ||
                  (!hostFormTemp && hostForm === null) ||
                  (!hostForm && hostFormTemp?.length === 0) ||
                  (!hostForm && hostFormTemp === null)) &&
                ((!deviceFormTemp && deviceForm?.length === 0) ||
                  (!deviceFormTemp && deviceForm === null) ||
                  (!deviceForm && deviceFormTemp?.length === 0) ||
                  (!deviceForm && deviceFormTemp === null)) &&
                ((!registryFormTemp && registryForm?.length === 0) ||
                  (!registryFormTemp && registryForm === null) ||
                  (!registryForm && registryFormTemp?.length === 0) ||
                  (!registryForm && registryFormTemp === null)) &&
                (!imageForm ||
                  (imageForm && imageForm.length === 0) ||
                  (!imageForm && imageForm === null)) &&
                (!dynamicGroupForm ||
                  (dynamicGroupForm && dynamicGroupForm.length === 0) ||
                  (!dynamicGroupForm && dynamicGroupForm === null))
              }
              onClick={addAssetToTaskScope}
            >
              {localeNewTask[currentLocale].form.btnAddAsset}{' '}
              {countOfSelectedAsset && countOfSelectedAsset !== 0
                ? `(${countOfSelectedAsset})`
                : null}
            </Button>
            <Button type="button" onClick={exitScopeArea}>
              {localeNewTask[currentLocale].form.btnCancel}
            </Button>
          </div>
        </div>
      </Dialog>
    </ErrorBoundary>
  );
}
