import type { INotificationContext } from '../hooks/useNotificationContext';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';

export class ResponseApiError extends Error {
  public code: ApiError['code'];

  public type: ApiError['type'];

  public captcha: string | undefined;

  constructor(data: ApiError) {
    console.log('constructor', data);
    super(data.message);
    this.name = 'ResponseApiError';
    this.code = data.code;
    this.type = data.type;
    if (data.type === 'CaptchaRequired' || data.type === 'UnauthorizedCaptcha')
      this.captcha = data.captcha;
  }
}

export function handleError(
  notification: ResponseApiError | ApiError,
  createNotification: INotificationContext['createNotification'],
): void {
  const currentError = {
    message:
      notification.code && notification.message
        ? `Code: ${notification.code}, ${notification.message}`
        : notification.message,
    type: 'error' as const,
  };

  createNotification(currentError);
}

// export async function testUnauthorizedError(error: ApiError): Promise<void> {
//   if (error && error.code === '401' && error.type === 'Unauthorized') {
//     console.log('Sending refresh new.....');
//     const { data } = await authAxios.post<UserLoginResponse>('/refresh', {
//       refreshToken: getRefreshToken() || undefined,
//     });
//     setTokens(data);
//   } else if (error && error.code === '401' && error.type === 'TokenExpired') {
//     removeAuthData();
//     history.push('/auth');
//   }
// }
