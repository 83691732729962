import qs from 'qs';
import type { SortDescriptor } from '@progress/kendo-data-query';
import type { UserUpdateResponse } from '../types/__generated/on-premise-solution/api/userUpdateResponse.v1';
import type { UserCreationRequest } from '../types/__generated/on-premise-solution/api/userCreationRequest.v1';
import type { UserCreationResponse } from '../types/__generated/on-premise-solution/api/userCreationResponse.v1';
import type { IPageState } from '../utils/helpers/types';
import { history } from '../utils/history';
import { vmInstance } from '../lib/axios/hooks/useVmAxios';
import { dashboardInstance } from '../lib/axios/hooks/useDashboardAxios';
import type { UsersDashboardResponse } from '../types/__generated/on-premise-solution/api/usersDashboardResponse.v1';
import type { UserUpdateRequest } from '../types/__generated/on-premise-solution/api/userUpdateRequest.v1';
import type { LdapCreationRequest } from '../types/__generated/on-premise-solution/api/ldapCreationRequest.v1';
import type { LdapCreationResponse } from '../types/__generated/on-premise-solution/api/ldapCreationResponse.v1';
import type { LdapsDashboardResponse } from '../types/__generated/on-premise-solution/api/ldapsDashboardResponse.v1';
import type { LdapDeletionResponse } from '../types/__generated/on-premise-solution/api/ldapDeletionResponse.v1';
import type { LdapUpdateRequest } from '../types/__generated/on-premise-solution/api/ldapUpdateRequest.v1';
import type { LdapUpdateResponse } from '../types/__generated/on-premise-solution/api/ldapUpdateResponse.v1';
import type { IFilterVal } from '../utils/filtering-in-table-test';
import { prepareFilterVal } from './filterVal-for-service';
import type { UserDashboardResponse } from '../types/__generated/on-premise-solution/api/userDashboardResponse.v1';
import type { TwoFactorAuthQRCodeResponse } from '../types/__generated/on-premise-solution/api/twoFactorAuthQRCodeResponse.v1';
import type { TwoFactorAuthCreateRequest } from '../types/__generated/on-premise-solution/api/twoFactorAuthCreateRequest.v1';
import type { TwoFactorAuthCreateResponse } from '../types/__generated/on-premise-solution/api/twoFactorAuthCreateResponse.v1';
import type { TwoFactorAuthUserDisableResponse } from '../types/__generated/on-premise-solution/api/twoFactorAuthUserDisableResponse.v1';
import type { LdapDashboardResponse } from '../types/__generated/on-premise-solution/api/ldapDashboardResponse.v1';

export async function getUsers(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  ldapId?: string,
): Promise<UsersDashboardResponse> {
  const result = await dashboardInstance.get<UsersDashboardResponse>('/users', {
    params: ldapId
      ? {
          skip: page.skip,
          limit: page.take,
          sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
          sortOrder: sort[0].dir,
          '$or[0][auth.ldapId]': `${ldapId}`,
          ...prepareFilterVal(filterVal),
        }
      : {
          skip: page.skip,
          limit: page.take,
          sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
          sortOrder: sort[0].dir,
          ...prepareFilterVal(filterVal),
        },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      if (!ldapId) {
        history.replace({
          pathname: `/lk/users/list?${stringifiedParams}`,
        });
      }

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function getUser(userId: string): Promise<UserDashboardResponse> {
  const result = await dashboardInstance.get<UserDashboardResponse>(`/users/${userId}`);

  return result.data;
}

export async function getAllUsers(): Promise<UsersDashboardResponse> {
  const result = await dashboardInstance.get<UsersDashboardResponse>('/users');

  return result.data;
}

export async function deleteUser(userId: string): Promise<UserUpdateResponse | null> {
  const result = await vmInstance.delete<UserUpdateResponse>(`/users/${userId}`);

  return result.data;
}

export async function createUser(payload: UserCreationRequest): Promise<UserCreationResponse> {
  if (payload.type && payload.type === 'ldap') {
    const { accountId, role, name, email, isDisabled, type, ldapId, uid, dn } = payload;

    const result = await vmInstance.post<UserCreationResponse>('/users', {
      accountId,
      role,
      name,
      email,
      type,
      isDisabled,
      ldapId,
      uid,
      dn,
    });

    return result.data;
  }
  if (payload.type && payload.type === 'activedirectory') {
    const { accountId, role, name, email, isDisabled, type, ldapId, username } = payload;

    const result = await vmInstance.post<UserCreationResponse>('/users', {
      accountId,
      role,
      name,
      email,
      isDisabled,
      type,
      ldapId,
      username,
    });

    return result.data;
  }
  const { accountId, role, name, login, email, password, isDisabled } = payload;

  const result = await vmInstance.post<UserCreationResponse>('/users', {
    accountId,
    role,
    name,
    login,
    email,
    password,
    isDisabled,
  });

  return result.data;
}

export async function getTfaQr(uid: string): Promise<TwoFactorAuthQRCodeResponse> {
  const result = await vmInstance.get<TwoFactorAuthQRCodeResponse>(`/users/${uid}/2fa/qr`);

  return result.data;
}

export async function confirmTfaQr(
  payload: TwoFactorAuthCreateRequest,
): Promise<TwoFactorAuthCreateResponse> {
  const { userId, code, secret } = payload;
  const result = await vmInstance.post<TwoFactorAuthCreateResponse>(`/users/${userId}/2fa`, {
    userId,
    code,
    secret,
  });

  return result.data;
}

export async function editUser(
  userId: string,
  payload: UserUpdateRequest,
): Promise<UserUpdateResponse> {
  const result = await vmInstance.patch<UserUpdateResponse>(`/users/${userId}`, payload);

  return result.data;
}

export async function disableTfa(userId: string): Promise<TwoFactorAuthUserDisableResponse> {
  const result = await vmInstance.patch<TwoFactorAuthUserDisableResponse>(
    `/users/${userId}/2fa/disable`,
  );

  return result.data;
}

// export async function changePasssword(
//   userId: string,
//   payload: UserPasswordChangeRequest,
// ): Promise<UserPasswordChangeResponse> {
//   const result = await vmInstance.post<UserPasswordChangeResponse>(
//     `/users/${userId}/password`,
//     payload,
//   );

//   return result.data;
// }

export async function getServerList(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
): Promise<LdapsDashboardResponse> {
  const result = await dashboardInstance.get<LdapsDashboardResponse>('/ldaps', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      if (document.location.pathname.includes('/servers/')) {
        history.replace({
          pathname: `/lk/users/servers?${stringifiedParams}`,
        });
      }

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function getServer(ldapId: string): Promise<LdapDashboardResponse> {
  const result = await dashboardInstance.get<LdapDashboardResponse>(`/ldap/${ldapId}`);

  return result.data;
}

export async function getSrvListForSelect(type: string): Promise<LdapsDashboardResponse> {
  const result = await dashboardInstance.get<LdapsDashboardResponse>(`/ldaps?type[$eq]=${type}`);

  return result.data;
}
export async function createServer({
  accountId,
  url,
  baseDN,
  type,
  name,
  servername,
  description,
  credentialId,
}: LdapCreationRequest): Promise<LdapCreationResponse> {
  const result = await vmInstance.post<LdapCreationResponse>('/ldaps', {
    accountId,
    url,
    baseDN,
    type,
    name,
    servername,
    description,
    credentialId,
  });

  return result.data;
}
export async function deleteServer(ldapId: string): Promise<LdapDeletionResponse | null> {
  const result = await vmInstance.delete<LdapDeletionResponse>(`/ldaps/${ldapId}`);

  return result.data;
}
export async function editSrv(
  ldapId: string,
  payload: LdapUpdateRequest,
): Promise<LdapUpdateResponse> {
  const result = await vmInstance.patch<LdapUpdateResponse>(`/ldaps/${ldapId}`, payload);

  return result.data;
}
