import { nanoid } from 'nanoid';
import React, { createContext, useState, useMemo, useContext, useCallback } from 'react';
import type { IChildrenNodes } from '../utils/helpers/types';

export interface INotification {
  message: string;
  type: 'info' | 'error' | 'warning' | 'success';
  id: string;
}

export interface INotificationContext {
  notifications: INotification[];
  setNotifications: (v: INotification[]) => void;
  createNotification(notification: Omit<INotification, 'id'>): void;
  deleteNotification(notification: INotification): void;
}

const NotificationModalContext = createContext<INotificationContext | null>(null);

export function NotificationProvider({ children }: IChildrenNodes): React.ReactElement {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const createNotification = useCallback(
    function createNotification(notification: Omit<INotification, 'id'>) {
      setNotifications((prev) => [{ ...notification, id: nanoid(8) }, ...prev]);
    },
    [setNotifications],
  );

  const deleteNotification = useCallback(
    function deleteNotification(notification: INotification) {
      setNotifications((prev) => prev.filter((m) => m.id !== notification.id));
    },
    [setNotifications],
  );

  const value = useMemo(
    () => ({
      notifications,
      setNotifications,
      createNotification,
      deleteNotification,
    }),
    [notifications, createNotification, deleteNotification],
  );

  return (
    <NotificationModalContext.Provider value={value}>{children}</NotificationModalContext.Provider>
  );
}
export const useNotificationContext = (): INotificationContext => {
  const context = useContext(NotificationModalContext);
  if (context === null) {
    throw new Error('NotificationContext must be used inside the NotificationProvider.Provider.');
  }

  return context;
};
