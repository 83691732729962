import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { useMutation } from 'react-query';
import { useEffect, useState } from 'react';
import localeLogin from '../../../utils/i18n/loginLocale/loginForm.json';
import type { FormDataValue } from '../../../utils/helpers/types';
import type { UserPasswordChangeResponse } from '../../../types/__generated/on-premise-solution/api/userPasswordChangeResponse.v1';
import type { UserPasswordChangeRequest } from '../../../types/__generated/on-premise-solution/api/userPasswordChangeRequest.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { changePasssword } from '../../../services/auth';
import { FormInput } from '../../common/form/FormInput';
import { FormNewPassword } from '../../common/form/FormNewPassword';
import { checkPassword } from '../../../utils/helpers/validation';
import { Button } from '../../common/baseElements/Button';
import styles from './Form.module.scss';
import { useAuthContext } from '../../../hooks/useAuthContext';
import localeButtons from '../../../utils/i18n/commonLocales/buttons.json';
import { useNotificationContext } from '../../../hooks/useNotificationContext';
import { handleError } from '../../../utils/errors';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeLogin;

export function ChangePasswordForm(): React.ReactElement {
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isIncorrectPassword, setIncorrectPassword] = useState<boolean>(false);
  const [isNewPasswordUsed, setNewPasswordUsed] = useState(false);

  const { setLogin, setExpiredPassword } = useAuthContext();
  const { createNotification } = useNotificationContext();

  const passwordChangeMutation = useMutation<
    UserPasswordChangeResponse,
    ApiError,
    UserPasswordChangeRequest
  >((payload) => changePasssword(payload), {
    onSuccess: (response) => {
      setExpiredPassword(false);
      setLogin('');
      createNotification({
        type: 'success',
        message: localeLogin[currentLocale].changePassword.successUpdatePass,
      });
      //   setCaptcha('');
      // if ('is2FARequired' in response && response.is2FARequired) {
      //   setTwoFactoredAuthPage(true);
      // } else {
      // setAccessToken(response.accessToken);
      // history.push('/lk/audit/tasks');
      // }
    },
    onError: (err) => {
      // if (err.code === '400' && err.type === 'BadRequestBody') setIncorrectPassword(true);
      if (err.code === '400' && err.type === 'BadRequestBody') setNewPasswordUsed(true);
      if (err.code === '401' && err.type === 'PasswordCompareError') setIncorrectPassword(true);
      if (err.code === '401' && err.type === 'TokenExpired') {
        setExpiredPassword(false);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        createNotification({
          type: 'error',
          message: localeLogin[currentLocale].errors.changePasswordExpired,
        });
      }
    },
  });

  const handleChangePassword = (dataItem: FormDataValue): void => {
    passwordChangeMutation.mutateAsync({
      new: dataItem.newPassword as string,
      current: dataItem.currentPassword as string,
    });
  };

  useEffect(() => {
    if (passwordChangeMutation.error) {
      handleError(passwordChangeMutation.error, createNotification);
    }
  }, [createNotification, passwordChangeMutation.error]);

  return (
    <div
      style={{
        display: 'flex',
        padding: '24px',
        border: '1px solid #ccc',
        borderRadius: '8px',
      }}
    >
      <Form
        onSubmit={(dataItem: FormDataValue): void => {
          handleChangePassword(dataItem);
          // setLoginError('');
          // setCaptchaText('');
        }}
        render={(): JSX.Element => (
          <FormElement>
            <fieldset className="k-form-fieldset">
              <legend className={styles.form_legend}>
                {/* {localeLogin[currentLocale].formTitle} */}
                {localeLogin[currentLocale].changePassword.title}
              </legend>
              <p>{localeLogin[currentLocale].changePassword.info}</p>
              <div className={styles.field_block}>
                <Field
                  name="currentPassword"
                  component={FormInput}
                  id="currentPassword"
                  type="password"
                  label={localeLogin[currentLocale].changePassword.currentPassword}
                  onChange={(e): void => {
                    if (isIncorrectPassword) setIncorrectPassword(false);
                    setCurrentPassword(e.target.value);
                  }}
                  inputValue={currentPassword}
                  // customValidator={checkLength}
                />
                {isIncorrectPassword && (
                  <div className="error-common-field">
                    <span>{localeLogin[currentLocale].errors.incorrectPass}</span>
                  </div>
                )}
              </div>
              <div
                className={styles.field_block}
                style={{
                  position: 'relative',
                }}
              >
                <Field
                  name="newPassword"
                  component={FormNewPassword}
                  //   component={FormInput}
                  id="newPassword"
                  type="password"
                  label={localeLogin[currentLocale].changePassword.newPassword}
                  onChange={(e): void => {
                    if (isNewPasswordUsed) setNewPasswordUsed(false);
                    setNewPassword(e.target.value);
                  }}
                  inputValue={newPassword}
                  tooltipStyles={{
                    top: '-28px',
                    right: '-460px',
                  }}
                  // customValidator={checkLength}
                />
                {isNewPasswordUsed && (
                  <div className="error-common-field">
                    <span>{localeLogin[currentLocale].errors.hasBeenUsed}</span>
                  </div>
                )}
              </div>
              <div className={styles.field_block}>
                <Field
                  name="confirmPassword"
                  component={FormInput}
                  id="confirmPassword"
                  type="password"
                  label={localeLogin[currentLocale].changePassword.confirmPassword}
                  onChange={(e): void => {
                    setConfirmPassword(e.target.value);
                  }}
                  inputValue={confirmPassword}
                  className={
                    newPassword && confirmPassword && newPassword !== confirmPassword
                      ? ''
                      : 'form-field-wrapper'
                  }
                  customValidator={checkPassword}
                />
                {newPassword && confirmPassword && newPassword !== confirmPassword && (
                  <div className="error-common-field">
                    <span>{localeLogin[currentLocale].errors.passNotMatch}</span>
                  </div>
                )}
              </div>
            </fieldset>
            <div className="k-form-buttons">
              <Button
                type="submit"
                disabled={
                  !currentPassword ||
                  !newPassword ||
                  !confirmPassword ||
                  confirmPassword !== newPassword
                }
              >
                {/* {localeLogin[currentLocale].loginBtn} */}
                {localeButtons[currentLocale].change}
              </Button>
            </div>
          </FormElement>
        )}
      />
    </div>
  );
}
