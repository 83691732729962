import { Dialog } from '@progress/kendo-react-dialogs';
import { loadMessages } from '@progress/kendo-react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useEffect, useCallback } from 'react';
import { Button } from '../../../common/baseElements/Button';
import localeCommonTable from '../../../../utils/i18n/commonLocales/table.json';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { editAsset } from '../../../../services/asset-service';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import type { AssetUpdateRequest } from '../../../../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';
import { getUrlHostGr } from '../../../../utils/helpers/host-group-list-helper';
import type { IDataHooks } from '../../../../utils/helpers/types';
import { CommonGroupList } from '../../../common/CommonGroupList';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeCommonTable;

loadMessages(localeCommonTable[currentLocale], currentLocale);

export function AddHostToGroupModal({
  assetId,
  hostname,
  startGroupsIds,
}: {
  assetId: string | undefined;
  hostname: string | undefined | null;
  startGroupsIds: string[] | null;
}): React.ReactElement {
  const { setOpenHostGroups, setGroupsIds, hostFormTemp, groupsIds, setHostFormTemp, setHostForm } =
    useHostModalContext();

  const urlParams = getUrlHostGr('AddHostToGroupModal');

  const queryClient = useQueryClient();

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'AddHostToGroupModal',
  };

  const handleClose = (): void => {
    setOpenHostGroups(false);
    setGroupsIds([]);
    setHostFormTemp(null);
    setHostForm(null);
  };

  useEffect(() => {
    setGroupsIds(startGroupsIds || []);
  }, []);

  const mutation = useMutation<
    AssetUpdateRequest,
    ApiError,
    { payloadAssetId: string; payload: AssetUpdateRequest }
  >(({ payloadAssetId, payload }) => editAsset(payloadAssetId, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('dashboardAssetId');
      handleClose();
    },
  });

  const saveAssetInGroups = useCallback(() => {
    mutation.mutateAsync({
      payloadAssetId: assetId || '',
      payload: {
        assetGroupIds:
          hostFormTemp && hostFormTemp.length
            ? hostFormTemp.map((v) => v.assetGroupId ?? '')
            : null,
        type: 'host',
      },
    });
    setOpenHostGroups(false);
  }, [assetId, hostFormTemp, mutation, setOpenHostGroups]);

  useEffect(() => {
    if (groupsIds) {
      setHostFormTemp(groupsIds.map((g) => ({ assetGroupId: g })));
    }
  }, [groupsIds, setHostFormTemp]);

  return (
    <Dialog
      // title={`${localeCommonTable[currentLocale].titleGrAsset} ${hostname}`}
      title={localeCommonTable[currentLocale].AddHostToGroupModalTitle}
      className="groups-edit"
      onClose={handleClose}
    >
      <div className="k-form">
        <div className="form-content">
          <CommonGroupList dataForHooks={dataForHooks} urlParams={urlParams} />
        </div>
        <div className="k-form-buttons">
          <Button type="submit" onClick={saveAssetInGroups}>
            {localeCommonTable[currentLocale].btnApply}
          </Button>
          <Button onClick={handleClose}>{localeCommonTable[currentLocale].btnCancel}</Button>
        </div>
      </div>
    </Dialog>
  );
}
