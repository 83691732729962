import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import { WrapperMenu } from './WrapperMenu';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function CiscoHardwareInfo({ data }: { data: InventorizationResponse }): React.ReactElement {
  return (
    <WrapperMenu title={locale[currentLocale].ciscoHardwareInfo.hardware}>
      {data && data.type === 'router' && data.class === 'cisco' && (
        <>
          <table className="column">
            <tbody>
              <tr>
                <td>
                  <span>{locale[currentLocale].ciscoHardwareInfo.model}</span>
                </td>
                <td>{data.result.hardware_info?.model}</td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].ciscoHardwareInfo.motherboardRevision}</span>
                </td>
                <td>{data.result.hardware_info?.motherboardRevision}</td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].ciscoHardwareInfo.motherboardAssembly}</span>
                </td>
                <td>{data.result.hardware_info?.motherboardAssembly}</td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].ciscoHardwareInfo.softwareImage}</span>
                </td>
                <td>{data.result.hardware_info?.softwareImage}</td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].ciscoHardwareInfo.daugheboardAssembly}</span>
                </td>
                <td>{data.result.hardware_info?.daugheboardAssembly}</td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].ciscoHardwareInfo.daugheboardSerial}</span>
                </td>
                <td>{data.result.hardware_info?.daugheboardSerial}</td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].ciscoHardwareInfo.topAssemblyPart}</span>
                </td>
                <td>{data.result.hardware_info?.topAssemblyPart}</td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].ciscoHardwareInfo.versionId}</span>
                </td>
                <td>{data.result.hardware_info?.versionId}</td>
              </tr>
            </tbody>
          </table>
          <table className="column">
            <tbody>
              <tr>
                <td>
                  <span>{locale[currentLocale].ciscoHardwareInfo.mac}</span>
                </td>
                <td>{data.result.hardware_info?.mac}</td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].ciscoHardwareInfo.ports}</span>
                </td>
                <td>{data.result.hardware_info?.ports}</td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].ciscoHardwareInfo.motherboardSerial}</span>
                </td>
                <td>{data.result.hardware_info?.motherboardSerial}</td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].ciscoHardwareInfo.modelRevision}</span>
                </td>
                <td>{data.result.hardware_info?.modelRevision}</td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].ciscoHardwareInfo.hardwareBoardRevision}</span>
                </td>
                <td>{data.result.hardware_info?.hardwareBoardRevision}</td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].ciscoHardwareInfo.systemSerial}</span>
                </td>
                <td>{data.result.hardware_info?.systemSerial}</td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].ciscoHardwareInfo.topAssemblyRevision}</span>
                </td>
                <td>{data.result.hardware_info?.topAssemblyRevision}</td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].ciscoHardwareInfo.cleiCode}</span>
                </td>
                <td>{data.result.hardware_info?.cleiCode}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      {data && data.type === 'switch' && data.class === 'aruba' && (
        <table className="column">
          <tbody>
            <tr>
              <td>
                <span>{locale[currentLocale].ciscoHardwareInfo.systemSerial}</span>
              </td>
              <td>{data.result.hardware_info?.serialNumber}</td>
              <td>
                <span>{locale[currentLocale].ciscoHardwareInfo.mac}</span>
              </td>
              <td>{data.result.hardware_info?.mac}</td>
            </tr>
          </tbody>
        </table>
      )}
    </WrapperMenu>
  );
}
