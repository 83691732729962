/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  cheackCredential,
  createCredentials,
  deleteCredentials,
  deleteVault,
  editCredentials,
  getVaultsList,
} from '../../services/credential-service';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import type { CredentialCreationRequest } from '../../types/__generated/on-premise-solution/api/credentialCreationRequest.v1';
import type { CredentialCreationResponse } from '../../types/__generated/on-premise-solution/api/credentialCreationResponse.v1';
import type { ICredential } from '../../utils/helpers/types';
import { getAccountId } from '../../services/local-storage-service';
import { useHostModalContext } from '../useHostModalContext';
import { ICredentialAndIp, useHostActions } from './useHostActions';
import { useRegisterContext } from '../useRegisterContext';
import type { AssetUpdateRequest } from '../../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';
import { useCredentialContext } from '../useCredentialContext';
import type { VaultCreationResponse } from '../../types/__generated/on-premise-solution/api/vaultCreationResponse.v1';
import { useHostCreateContext } from '../useHostCreateContext';
import type { VaultsResponse } from '../../types/__generated/on-premise-solution/api/vaultsResponse.v1';
import { createVaults } from '../../utils/helpers/credential-helper';
import { useAssetCreatePropContext } from '../useAssetCreatePropContext';
import { useCommonContext } from '../useCommonContext';
import type { CredentialCheckResponse } from '../../types/__generated/on-premise-solution/api/credentialCheckResponse.v1';
import type { CredentialCheckRequest } from '../../types/__generated/on-premise-solution/api/credentialCheckRequest.v1';
import { useServerContext } from '../useServerContext';
import { useTaskAddContext } from '../useTaskAddContext';

export function useCredential(): ICredential {
  const queryClient = useQueryClient();

  const {
    setAddCredential,
    selectedCredential,
    setSelectedCredential,
    setCheckCredentilsResp,
    setCheckingCredential,
    setEdit,
    setCredentialData,
    setCredentialHost,
    credentialData,
    typeCred,
    nameCred,
    description,
    nameCredStorage,
    username,
    password,
    privateKey,
    passphrase,
    setTypeCred,
    setNameCred,
    setDescription,
    setPassword,
    setPrivateKey,
    setPassphrase,
    setUsername,
    setPasswordConfirm,
    token,
    setToken,
    setCredentialDev,
    isAddCredential,
    initialState,
    setErrorMutation,
    setCheckErrorMutation,
    ipKdc,
    setIpKdc,
    realm,
    setRealm,
    authType,
    setAuthType,
    community,
    authProtocol,
    authKey,
    privacyKey,
    authLevel,
    privacyProtocol,
    version,
    setVersion,
    dn,
    setDn,
    uid,
    setUid,
    certificate,
    setCertificate,
  } = useCredentialContext();

  const { hostForm, setHostForm, ipHost, setHostName, isAssetName, setOpen } =
    useHostModalContext();

  const { setCredentialReg } = useRegisterContext();

  const { handleEditHost } = useHostActions();

  const { hostCreateData } = useHostCreateContext();

  const { resultCheckIp } = useCommonContext();

  const [isConfirm, setConfirm] = useState(false);

  const { selectedImportance, perimeterImpact } = useAssetCreatePropContext();

  const { mutationEditServer, serverForm, setServerForm, setCredentialSrv } = useServerContext();

  const { taskType } = useTaskAddContext();

  const handleWindow = (): void => {
    setAddCredential(false);
    setCredentialData(null);
    setHostForm(null);
    // setIpHost('');
    setHostName('');
    setCheckingCredential(false);
    setCheckCredentilsResp(undefined);
    queryClient.cancelMutations();
    setEdit(false);
    setTypeCred('');
    setNameCred('');
    setDescription(null);
    setPassword('');
    setPrivateKey('');
    setPassphrase('');
    setUsername('');
    setPasswordConfirm('');
    setToken('');
    setCheckErrorMutation('');
    setIpKdc('');
    setRealm('');
    setAuthType('');
    setVersion('');
    setDn('');
    setUid('');
    if (taskType.value.id !== 'asset_synchronize') {
      setServerForm(null);
    }
    setOpen(false);
    setCertificate('');
  };

  const mutationNewCredentials = useMutation<
    CredentialCreationResponse,
    ApiError,
    CredentialCreationRequest
  >((payload) => createCredentials(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('credentials');
      queryClient.invalidateQueries('dashboardAssetId');
      setAddCredential(false);
      setServerForm(null);
      handleWindow();
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const mutationEditCredentials = useMutation<
    CredentialCreationResponse,
    ApiError,
    CredentialCreationRequest
  >((payload) => editCredentials(credentialData?.credentialId, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('credentials');
      queryClient.invalidateQueries('dashboardAssetId');
      setAddCredential(false);
      setCredentialData(null);
      setServerForm(null);
      handleWindow();
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const mutationDeleteCredentials = useMutation<
    CredentialCreationResponse,
    ApiError,
    CredentialCreationResponse['credentialId']
  >((credentialId) => deleteCredentials(credentialId), {
    onSuccess: () => {
      queryClient.invalidateQueries('credentials');
      setAddCredential(false);
      setCredentialData(null);
      setConfirm(false);
      handleWindow();
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const mutationDeleteVault = useMutation<
    VaultCreationResponse,
    ApiError,
    VaultCreationResponse['vaultId']
  >((credentialId) => deleteVault(credentialId), {
    onSuccess: () => {
      queryClient.invalidateQueries('vaultslList');
      setAddCredential(false);
      setCredentialData(null);
      setConfirm(false);
      handleWindow();
    },
  });

  const query = useQuery<VaultsResponse, ApiError>(['vaultslList'], () => getVaultsList(), {
    keepPreviousData: true,
    enabled: isAddCredential || !!credentialData,
  });

  const { data } = query;

  const vaultIdList = createVaults(data);

  const handleSubmit = (): void => {
    const assetsData = hostForm?.map((h) => ({ assetId: h.assetId }));
    if (!credentialData || hostCreateData?.assetType) {
      const newCredential = {
        type: typeCred,
        accountId: getAccountId(),
        name: nameCred,
        description: description || undefined,
        // port: 22,
        authType: authType || undefined,
        vaultId:
          vaultIdList && Array.isArray(vaultIdList) && vaultIdList.length === 1
            ? vaultIdList[0].id
            : nameCredStorage.id,
        community: community || undefined,
        version: version || undefined,
        ldapId: (serverForm && serverForm.length > 0 && serverForm[0].ldapId) || undefined,
        credentials:
          username || typeCred === 'ldap'
            ? {
                username: username || undefined,
                token: token || undefined,
                password: password || undefined,
                privateKey: privateKey || undefined,
                passphrase: passphrase || undefined,
                ipKdc: ipKdc || undefined,
                realm: realm || undefined,
                authProtocol: typeCred === 'snmp' ? authProtocol : undefined,
                authKey: typeCred === 'snmp' ? authKey : undefined,
                privacyKey: typeCred === 'snmp' ? privacyKey : undefined,
                authLevel: typeCred === 'snmp' ? authLevel : undefined,
                privacyProtocol: typeCred === 'snmp' ? privacyProtocol : undefined,
                dn: typeCred === 'ldap' ? dn : undefined,
                uid: typeCred === 'ldap' ? uid : undefined,
                certificate: certificate || undefined,
              }
            : undefined,
        assets: assetsData ? [...assetsData] : undefined,
      };
      mutationNewCredentials.mutateAsync(newCredential as unknown as CredentialCreationRequest);
    } else {
      const editCredential = {
        type: typeCred || initialState?.typeCred,
        accountId: credentialData.accountId,
        name: nameCred && initialState?.nameCred !== nameCred ? nameCred : undefined,
        authType: authType || undefined,
        description:
          description && description.length > 0 && initialState?.description !== description
            ? description
            : description?.length === 0
            ? null
            : undefined,
        vaultId:
          nameCredStorage.id && initialState?.vaultId !== nameCredStorage.id
            ? nameCredStorage.id
            : initialState?.vaultId,
        community: community && initialState?.community !== community ? community : undefined,
        version: version || undefined,
        ldapId: (serverForm && serverForm[0].ldapId) || undefined,
        credentials:
          (initialState?.username && initialState?.username !== username) ||
          (initialState?.token && initialState?.token !== token) ||
          (initialState?.password && initialState?.password !== password) ||
          (initialState?.privateKey && initialState?.privateKey !== privateKey) ||
          (initialState?.passphrase && initialState?.passphrase !== passphrase) ||
          (initialState?.ipKdc && initialState?.ipKdc !== ipKdc) ||
          (initialState?.realm && initialState?.realm !== realm) ||
          (initialState?.dn && initialState?.dn !== realm) ||
          (initialState?.uid && initialState?.uid !== realm) ||
          initialState?.certificate !== certificate ||
          (version === 'v3' &&
            (authProtocol || authKey || privacyKey || authLevel || privacyProtocol))
            ? {
                username: username || undefined,
                token: token && initialState?.token !== token ? token : undefined,
                ipKdc: ipKdc && initialState?.ipKdc !== ipKdc ? ipKdc : undefined,
                realm: realm && initialState?.realm !== realm ? realm : undefined,
                password:
                  initialState?.password !== password && password
                    ? password
                    : !password
                    ? null
                    : undefined,
                privateKey:
                  initialState?.privateKey !== privateKey && privateKey
                    ? privateKey
                    : !privateKey
                    ? null
                    : undefined,
                passphrase:
                  initialState?.passphrase !== passphrase && passphrase
                    ? passphrase
                    : !passphrase
                    ? null
                    : undefined,
                authProtocol: typeCred === 'snmp' ? authProtocol : undefined,
                authKey: typeCred === 'snmp' ? authKey : undefined,
                privacyKey: typeCred === 'snmp' ? privacyKey : undefined,
                authLevel: typeCred === 'snmp' ? authLevel : undefined,
                privacyProtocol: typeCred === 'snmp' ? privacyProtocol : undefined,
                dn: typeCred === 'ldap' ? dn : undefined,
                uid: typeCred === 'ldap' ? uid : undefined,
                certificate:
                  certificate && certificate.length > 0 && initialState?.certificate !== certificate
                    ? certificate
                    : certificate?.length === 0
                    ? null
                    : undefined,
              }
            : undefined,
      };
      mutationEditCredentials.mutateAsync(editCredential as unknown as CredentialCreationRequest);
    }
  };

  const mutationCheckCredentials = useMutation<
    CredentialCheckResponse,
    ApiError,
    { credentialId: string | null | undefined; payload: CredentialCheckRequest }
  >(({ credentialId, payload }) => cheackCredential(credentialId, payload), {
    onSuccess: (response) => {
      setCheckCredentilsResp(response);
      setCheckingCredential(false);
    },
    onError: (resp) => {
      setCheckErrorMutation(resp.message);
      setCheckingCredential(false);
    },
  });

  const checkCredentialAndIp = (payload: ICredentialAndIp): void => {
    setCheckingCredential(true);
    setCheckCredentilsResp(undefined);
    const credentialId = payload.сredentialId;
    mutationCheckCredentials.mutateAsync({
      credentialId,
      payload: {
        sshPort: payload.typeOfPort && payload.typeOfPort === 'ssh' ? payload.port : undefined,
        winrmPort: payload.typeOfPort && payload.typeOfPort === 'winrm' ? payload.port : undefined,
        snmpPort: payload.typeOfPort && payload.typeOfPort === 'snmp' ? payload.port : undefined,
        ldap:
          payload.url || payload.baseDN || payload.ldapId
            ? { url: payload.url, baseDN: payload.baseDN, ldapId: payload.ldapId }
            : undefined,
        ip:
          !resultCheckIp && !isAssetName && !isAssetName && payload.ip
            ? payload.ip.trimEnd()
            : undefined,
        hostname:
          payload.authType && payload.authType === 'kerberos'
            ? payload.hostname
            : isAssetName && payload.ip
            ? payload.ip
            : undefined,
      },
    });
  };

  const handleConfirm = (): void => {
    setConfirm(true);
  };

  const handleDelete = (credentialId: string): void => {
    mutationDeleteCredentials.mutate(credentialId);
  };

  const handleDeleteVault = (vaultId: string): void => {
    mutationDeleteVault.mutate(vaultId);
  };

  const setCredForAsset = (): void => {
    const payload = {
      accountId: selectedCredential?.accountId,
      assetGroupIds: selectedCredential?.assetGroupIds,
      credentialId: selectedCredential?.credentialId,
      osType: selectedCredential?.osType,
      ip:
        !resultCheckIp && !isAssetName && selectedCredential?.ip
          ? selectedCredential?.ip.trimEnd()
          : undefined,
      // type: 'host' as const,
      importance: selectedImportance?.val,
      // subType: selectedHostType?.val,
      // subType: document.location.pathname.includes('/lk/assets/devices')
      //   ? 'network_device'
      //   : selectedHostType?.val,
      perimeterImpact: perimeterImpact ? 1 : 0.5,
      winrmPort: hostCreateData?.assetType === 'windows' ? 5985 : undefined,
      smbPort: hostCreateData?.assetType === 'windows' ? 445 : undefined,
      sshPort: hostCreateData?.assetType === 'linux' ? 22 : undefined,
      // hostname: hostCreateData?.assetType === 'windows' && hostName ? hostName : undefined,
      hostname: isAssetName && selectedCredential?.ip ? selectedCredential?.ip : undefined,
    };

    // for host:
    if (selectedCredential && selectedCredential.assetType === 'host') {
      const hostPayload = {
        ...payload,
        type: 'host',
        // ip: ipHost || undefined,
      } as AssetUpdateRequest;
      setCredentialHost({
        credentialId: (selectedCredential && selectedCredential.credentialId) || undefined,
        credentialName: selectedCredential?.credentialName,
        credentialType: selectedCredential?.type,
      });
      if (ipHost || selectedCredential.hostname || selectedCredential.osType)
        handleEditHost(selectedCredential?.assetId, hostPayload);
      // handleEditHost(selectedCredential?.assetId, hostPayload);
      setSelectedCredential(null);
    }

    // for network device:
    if (
      selectedCredential &&
      (selectedCredential.assetType === 'router' ||
        selectedCredential.assetType === 'firewall' ||
        selectedCredential.assetType === 'switch' ||
        selectedCredential.assetType === 'hypervisor' ||
        !selectedCredential.assetType) &&
      selectedCredential.type !== 'container_registry'
    ) {
      setCredentialDev({
        credentialId: selectedCredential?.credentialId,
        credentialName: selectedCredential?.credentialName,
        credentialType: selectedCredential?.type,
        credentialSshPort: selectedCredential.sshPort,
      });
      const hostPayload = {
        ...payload,
        type: selectedCredential.assetType,
      } as AssetUpdateRequest;
      if (selectedCredential?.assetId) handleEditHost(selectedCredential?.assetId, hostPayload);
      setSelectedCredential(null);
    }

    // for image:
    if (selectedCredential && selectedCredential.type === 'container_registry') {
      setCredentialReg({
        credentialId: selectedCredential?.credentialId
          ? selectedCredential?.credentialId
          : undefined,
        credentialName: selectedCredential?.credentialName
          ? selectedCredential?.credentialName
          : undefined,
      });
      setSelectedCredential(null);
    }

    // for __Server__
    if (
      selectedCredential &&
      (selectedCredential.type === 'ldap' || selectedCredential.type === 'activedirectory')
    ) {
      if (selectedCredential && selectedCredential.assetType === 'server') {
        setCredentialSrv({
          credentialId: selectedCredential.credentialId,
          credentialName: selectedCredential.credentialName,
          credentialType: selectedCredential.type,
        });
      }
      if (document.location.pathname.includes('credentials/list') || selectedCredential.ldapId) {
        mutationEditServer.mutate({
          ldapId: selectedCredential.ldapId || '',
          payload: {
            credentialId: selectedCredential.credentialId || undefined,
          },
        });
      }

      setSelectedCredential(null);
    }

    if (
      selectedCredential?.assetType === 'commonwinrm' ||
      selectedCredential?.assetType === 'commonssh'
    ) {
      setSelectedCredential(null);
    }
  };

  return {
    isConfirm,
    setConfirm,
    handleSubmit,
    handleWindow,
    handleConfirm,
    handleDelete,
    setCredForAsset,
    // setCredentialForImage,
    handleDeleteVault,
    vaultIdList,
    checkCredentialAndIp,
  };
}
