import type { TRegisterContent } from '../../hooks/useRegisterContext';
import { getUserData } from '../../services/local-storage-service';
import type { BulletinWhitelistCreationRequest } from '../../types/__generated/on-premise-solution/api/bulletinWhitelistCreationRequest.v1';
import type {
  BulletinWhitelistDashboardResponse,
  WhitelistedBulletin,
} from '../../types/__generated/on-premise-solution/api/bulletinWhitelistDashboardResponse.v1';
import type {
  IDeviceForm,
  IHostForm,
  IImageForm,
  IWhiteListContext,
  TAssetDynamicGroups,
  TAssetGroups,
  TAssets,
  TDynamicGroupForm,
  THostModalContent,
  TNetDeviceContent,
} from './types';

interface IDataWhiteListSubmit {
  assets: Exclude<BulletinWhitelistCreationRequest['scope']['assets'], undefined>;
  assetGroups: Exclude<BulletinWhitelistCreationRequest['scope']['assetGroups'], undefined>;
  assetDynamicGroups: Exclude<
    BulletinWhitelistCreationRequest['scope']['assetDynamicGroups'],
    undefined
  >;
}

interface IAssetType {
  host: boolean;
  image: boolean;
  router: boolean;
  switch: boolean;
  firewall: boolean;
  all: boolean;
}

interface ITypesGrid {
  name: string;
  description: string;
  addedAt: string;
  addedBy:
    | {
        source: 'user';
        id: string;
      }
    | {
        source: 'onpremise';
      };
}

/* eslint-disable no-nested-ternary */
function getTimeEnding(
  timeValue: number,
  timeType: string,
  postfix: boolean,
  currentLocale: 'ru-RU' | 'en-EN',
): string {
  const isSingle = timeValue === 1;
  if (!timeValue) return '';
  switch (timeType) {
    case 'm':
      if (currentLocale === 'ru-RU')
        return `${timeValue} ${
          (timeValue - 1) % 10 > 4 || Math.floor(timeValue / 10) % 10 === 1
            ? 'минут'
            : timeValue % 10 === 1
            ? `минут${postfix ? 'у' : 'а'}`
            : 'минуты'
        }`;

      return `${timeValue} ${isSingle ? 'minute' : 'minutes'}`;
    case 'h':
      if (currentLocale === 'ru-RU')
        return `${timeValue} ${
          (timeValue - 1) % 10 > 4 || Math.floor(timeValue / 10) % 10 === 1
            ? 'часов'
            : timeValue % 10 === 1
            ? 'час'
            : 'часа'
        }`;

      return `${timeValue} ${isSingle ? 'hour' : 'hours'}`;
    case 'd':
      if (currentLocale === 'ru-RU')
        return `${timeValue} ${
          (timeValue - 1) % 10 > 4 || Math.floor(timeValue / 10) % 10 === 1
            ? 'дней'
            : timeValue % 10 === 1
            ? 'день'
            : 'дня'
        }`;

      return `${timeValue} ${isSingle ? 'day' : 'days'}`;

    default:
      break;
  }

  return '';
}
export function getPastTime(
  timestamp: number | undefined,
  currentLocale: 'ru-RU' | 'en-EN',
  timeFactor?: number,
  postfix?: string,
): string {
  if (!timestamp && typeof timestamp !== 'number') return '-';
  if (timestamp < 60)
    return `${currentLocale === 'ru-RU' ? 'менее минуты назад' : 'less than minute ago'}`;

  const times = [60 * 60 * 24, 60 * 60, 60].map(
    (t, i) =>
      Math.trunc(timestamp / ((t * 1000) / (timeFactor || 1))) % (i ? (i > 1 ? 60 : 24) : t),
  );
  const timeTypes = ['d', 'h', 'm'];

  return `${times
    .map((t, i) => getTimeEnding(t, timeTypes[i], !!postfix, currentLocale))
    .join(' ')} ${postfix || ''}`;
}

export function prepareWhiteListSubmit(
  hostForm: IHostForm[] | null,
  deviceForm: IDeviceForm[] | null,
  imageForm: IImageForm[] | null,
  dynamicGroupForm: TDynamicGroupForm[] | null,
): IDataWhiteListSubmit {
  const currentUser = getUserData();

  const assets = [] as BulletinWhitelistCreationRequest['scope']['assets'];

  const assetGroups = [] as BulletinWhitelistCreationRequest['scope']['assetGroups'];

  const assetDynamicGroups = [] as BulletinWhitelistCreationRequest['scope']['assetDynamicGroups'];

  if (hostForm) {
    hostForm.forEach((a) => {
      if (a.assetId)
        assets?.push({
          assetId: a.assetId,
          addedBy: {
            source: 'user',
            id: currentUser!.id,
          },
          addedAt: new Date().toISOString(),
        });
      if (a.assetGroupId)
        assetGroups?.push({
          assetGroupId: a.assetGroupId,
          addedBy: {
            source: 'user',
            id: currentUser!.id,
          },
          addedAt: new Date().toISOString(),
        });
    });
  }
  if (dynamicGroupForm) {
    dynamicGroupForm.forEach((g) => {
      if (g.assetDynamicGroupId)
        assetDynamicGroups?.push({
          assetDynamicGroupId: g.assetDynamicGroupId,
          addedBy: {
            source: 'user',
            id: currentUser!.id,
          },
          addedAt: new Date().toISOString(),
        });
    });
  }
  if (deviceForm) {
    deviceForm.forEach((a) => {
      if (a.assetId && !hostForm?.find((val) => val.assetId === a.assetId)) {
        assets?.push({
          assetId: a.assetId,
          addedBy: {
            source: 'user',
            id: currentUser!.id,
          },
          addedAt: new Date().toISOString(),
        });
      }
    });
  }
  if (imageForm) {
    imageForm.forEach((a) => {
      if (a.assetId && !hostForm?.find((val) => val.assetId === a.assetId))
        assets?.push({
          assetId: a.assetId,
          addedBy: {
            source: 'user',
            id: currentUser!.id,
          },
          addedAt: new Date().toISOString(),
        });
    });
  }

  return {
    assets: assets && assets.length > 0 ? assets : null,
    assetGroups: assetGroups && assetGroups.length > 0 ? assetGroups : null,
    assetDynamicGroups:
      assetDynamicGroups && assetDynamicGroups.length > 0 ? assetDynamicGroups : null,
  };
}

export function prepareWhitelistUpdate(
  val: BulletinWhitelistDashboardResponse,
  setHostForm: THostModalContent['setHostForm'],
  setDeviceForm: TNetDeviceContent['setDeviceForm'],
  setImageForm: TRegisterContent['setImageForm'],
  setBulletinForm: React.Dispatch<React.SetStateAction<WhitelistedBulletin[] | null>>,
  setDynamicGroupForm: React.Dispatch<React.SetStateAction<TDynamicGroupForm[] | null>>,
): void {
  const hosts = val.scope.assets
    ?.filter((a: TAssets) => a.type === 'host')
    .map((a) => ({
      assetId: a.assetId,
      hostname: (a.type === 'host' && a.hostname) || undefined,
    }));

  const groups = val.scope.assetGroups
    ?.filter((a: TAssetGroups) => a.type === 'host')
    .map((a) => ({
      assetGroupId: a.assetGroupId,
      name: (a.type === 'host' && a.name) || undefined,
    }));

  const dynamicGroups = val.scope.assetDynamicGroups?.map((g: TAssetDynamicGroups) => ({
    assetDynamicGroupId: g.assetDynamicGroupId,
    name: g.name,
  }));

  if (dynamicGroups) setDynamicGroupForm(dynamicGroups);

  if (groups && !hosts) {
    setHostForm(groups);
  }

  if (hosts && !groups) {
    setHostForm(hosts);
  }

  if (hosts && groups) {
    setHostForm([...hosts, ...groups]);
  }

  const devices = val.scope.assets
    ?.filter((a: TAssets) => a.type !== 'host' && a.type !== 'image')
    .map((a) => ({
      assetId: a.assetId,
      hostname: 'hostname' in a ? a.hostname : undefined,
    }));
  if (devices) setDeviceForm(devices);

  const images = val.scope.assets
    ?.filter((a: TAssets) => a.type === 'image')
    .map((a) => ({
      assetId: a.assetId,
      imageNames: (a.type === 'image' && a.imageNames[0]) || '',
    }));

  if (images) setImageForm(images);

  if (val.bulletins) setBulletinForm(val.bulletins);
}
export function prepareAssetType(
  assetType: IAssetType,
): Exclude<BulletinWhitelistCreationRequest['scope']['assetTypes'], undefined> {
  const currentUser = getUserData();

  let types = [] as BulletinWhitelistCreationRequest['scope']['assetTypes'];

  Object.values(assetType).forEach((a, i) => {
    if (a && Object.keys(assetType)[i] === 'all') {
      types = [
        {
          assetType: 'host',
          addedBy: {
            source: 'user',
            id: currentUser!.id,
          },
          addedAt: new Date().toISOString(),
        },
        {
          assetType: 'image',
          addedBy: {
            source: 'user',
            id: currentUser!.id,
          },
          addedAt: new Date().toISOString(),
        },
        {
          assetType: 'router',
          addedBy: {
            source: 'user',
            id: currentUser!.id,
          },
          addedAt: new Date().toISOString(),
        },
        {
          assetType: 'switch',
          addedBy: {
            source: 'user',
            id: currentUser!.id,
          },
          addedAt: new Date().toISOString(),
        },
        {
          assetType: 'firewall',
          addedBy: {
            source: 'user',
            id: currentUser!.id,
          },
          addedAt: new Date().toISOString(),
        },
        {
          assetType: 'hypervisor',
          addedBy: {
            source: 'user',
            id: currentUser!.id,
          },
          addedAt: new Date().toISOString(),
        },
      ];
    } else if (a) {
      if (
        (assetType && Object.keys(assetType)[i] === 'all') ||
        (assetType && types?.find((t) => t.assetType.includes(Object.keys(assetType)[i])))
      )
        return;
      const typeOfAsset = Object.keys(assetType)[i] as
        | 'host'
        | 'image'
        | 'router'
        | 'switch'
        | 'firewall'
        | 'hypervisor';
      types?.push({
        assetType: typeOfAsset,
        addedBy: {
          source: 'user',
          id: currentUser!.id,
        },
        addedAt: new Date().toISOString(),
      });
    }
  });

  return types && types.length > 0 ? types : null;
}
export function prepareAssetTypeGrid(
  assetType: BulletinWhitelistDashboardResponse['scope']['assetTypes'],
  currentLocale: 'ru-RU' | 'en-EN',
): ITypesGrid[] | null {
  let types = [] as ITypesGrid[];

  assetType?.forEach((a) => {
    if (
      a.assetType !== 'host' &&
      a.assetType !== 'firewall' &&
      a.assetType !== 'image' &&
      a.assetType !== 'router' &&
      a.assetType !== 'switch'
    ) {
      types = [
        {
          name: currentLocale === 'ru-RU' ? 'Хост' : 'Host',
          addedAt: a.addedAt,
          addedBy: a.addedBy,
          description: currentLocale === 'ru-RU' ? 'Выбраны все хосты' : 'All hosts selected',
        },
        {
          name: currentLocale === 'ru-RU' ? 'Образ' : 'Image',
          addedAt: a.addedAt,
          addedBy: a.addedBy,
          description: currentLocale === 'ru-RU' ? 'Выбраны все образы' : 'All images selected',
        },
        {
          name: currentLocale === 'ru-RU' ? 'Маршрутизатор' : 'Router',
          addedAt: a.addedAt,
          addedBy: a.addedBy,
          description:
            currentLocale === 'ru-RU' ? 'Выбраны все маршрутизаторы' : 'All images routers',
        },
        {
          name: currentLocale === 'ru-RU' ? 'Коммутатор' : 'Switch',
          addedAt: a.addedAt,
          addedBy: a.addedBy,
          description:
            currentLocale === 'ru-RU' ? 'Выбраны все коммутаторы' : 'All switchs selected',
        },
        {
          name: currentLocale === 'ru-RU' ? 'Межсетевой экран' : 'Firewall',
          addedAt: a.addedAt,
          addedBy: a.addedBy,
          description:
            currentLocale === 'ru-RU' ? 'Выбраны все межсетевые экраны' : 'All images firewalls',
        },
      ];
    } else if (a.assetType === 'host') {
      types.push({
        name: currentLocale === 'ru-RU' ? 'Хост' : 'Host',
        addedAt: a.addedAt,
        addedBy: a.addedBy,
        description: currentLocale === 'ru-RU' ? 'Выбраны все хосты' : 'All hosts selected',
      });
    } else if (a.assetType === 'image') {
      types.push({
        name: currentLocale === 'ru-RU' ? 'Образ' : 'Image',
        addedAt: a.addedAt,
        addedBy: a.addedBy,
        description: currentLocale === 'ru-RU' ? 'Выбраны все образы' : 'All images selected',
      });
    } else if (a.assetType === 'router') {
      types.push({
        name: currentLocale === 'ru-RU' ? 'Маршрутизатор' : 'Router',
        addedAt: a.addedAt,
        addedBy: a.addedBy,
        description:
          currentLocale === 'ru-RU' ? 'Выбраны все маршрутизаторы' : 'All images routers',
      });
    } else if (a.assetType === 'switch') {
      types.push({
        name: currentLocale === 'ru-RU' ? 'Коммутатор' : 'Switch',
        addedAt: a.addedAt,
        addedBy: a.addedBy,
        description: currentLocale === 'ru-RU' ? 'Выбраны все коммутаторы' : 'All switchs selected',
      });
    } else if (a.assetType === 'firewall') {
      types.push({
        name: currentLocale === 'ru-RU' ? 'Межсетевой экран' : 'Firewall',
        addedAt: a.addedAt,
        addedBy: a.addedBy,
        description:
          currentLocale === 'ru-RU' ? 'Выбраны все межсетевые экраны' : 'All images firewalls',
      });
    }
  });

  return types.length > 0 ? types : null;
}

interface IAssetType {
  [key: string]: boolean;
}
export function usedAssetType(assetType: IAssetType): string[] | null {
  const types = [] as string[];
  Object.keys(assetType).forEach((a) => {
    if (assetType[a] && a !== 'all') {
      types.push(a);
    }
  });

  return types.length > 0 ? types : null;
}
export function usedEditAssetType(
  assetType: BulletinWhitelistDashboardResponse['scope']['assetTypes'],
): IWhiteListContext['assetType'] {
  const assetTypes = {
    all: !!assetType?.find(
      (a) =>
        a.assetType !== 'host' &&
        a.assetType !== 'firewall' &&
        a.assetType !== 'image' &&
        a.assetType !== 'router' &&
        a.assetType !== 'switch',
    ),
    host: !!assetType?.find((a) => a.assetType === 'host'),
    firewall: !!assetType?.find((a) => a.assetType === 'firewall'),
    image: !!assetType?.find((a) => a.assetType === 'image'),
    router: !!assetType?.find((a) => a.assetType === 'router'),
    switch: !!assetType?.find((a) => a.assetType === 'switch'),
  } as IWhiteListContext['assetType'];

  return assetTypes;
}
