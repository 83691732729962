/* eslint-disable prettier/prettier */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';

export function CellLog(props: GridCellProps): React.ReactElement {
  const { dataItem, className } = props;

  return (
    <td>
      {((dataItem.partialResults && dataItem.partialResults.hasPartialResults) ||
        (dataItem.result && 'data' in dataItem.result && dataItem.result.data.log)) && (
        <NavLink
          to={`/lk/task/actions/${dataItem.taskActionId}/${
            className && className.includes('kb') ? 'kb_install' : 'software_management'
          }/logs`}
        >
          <span className="k-icon k-font-icon k-i-file-bac" />
        </NavLink>
      )}
      {dataItem.status === 'success' &&
        dataItem.partialResults &&
        !dataItem.result.data.log &&
        !dataItem.partialResults.hasPartialResults && (
          <NavLink
            to={`/lk/task/actions/${dataItem.taskActionId}/${
              className && className.includes('kb') ? 'kb_install' : 'software_management'
            }/logs`}
          >
            <span className="k-icon k-font-icon k-i-file-bac" />
          </NavLink>
        )}
      {/* {dataItem.status === 'processing' &&
        dataItem.partialResults &&
        !dataItem.partialResults.hasPartialResults && <span className="k-i-loader" />} */}
      {((dataItem.result &&
        'data' in dataItem.result &&
        !dataItem.result.data.log &&
        (dataItem.status === 'fail' || dataItem.status === 'timeout') &&
        dataItem.partialResults &&
        !dataItem.partialResults.hasPartialResults) ||
        (!dataItem.result &&
          dataItem.partialResults &&
          !dataItem.partialResults.hasPartialResults)) && (
          <span className="k-icon k-i-cancel-outline" />
      )}
    </td>
  );
}
