/* eslint-disable no-nested-ternary */
import '../../TaskDescription.module.scss';
import localeTaskDescription from '../../../../../utils/i18n/taskLocale/taskDescription.json';
import localeTask from '../../../../../utils/i18n/taskLocale/task.json';
import type { TaskExecutionDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import { TaskType } from '../../common/TaskType';

export function ColumnSecond({
  data,
  currentLocale,
}: {
  data: TaskExecutionDashboardResponse;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  return (
    <table className="column">
      <tbody>
        <tr className="startby">
          <td>
            <span>{localeTaskDescription[currentLocale].summary.launchedBy}</span>
          </td>
          <td>
            {data.createdBy.source === 'user' ? <span>{data.createdBy.name}</span> : <span>-</span>}
          </td>
        </tr>
        <tr className="taskid">
          <td>
            <span>{localeTaskDescription[currentLocale].summary.id}</span>
          </td>
          <td>
            <span>{data.taskExecutionId}</span>
          </td>
        </tr>
        <tr className="taskType">
          <td>
            <span>{localeTaskDescription[currentLocale].summary.taskType}</span>
          </td>
          <td>
            {(data.taskType === 'host' || data.taskType === 'asset') && (
              <span>{localeTaskDescription[currentLocale].summary.taskTypeHost}</span>
            )}
            {data.taskType === 'report' && (
              <span>{localeTaskDescription[currentLocale].summary.taskTypeReport}</span>
            )}
            {data.taskType === 'image' && (
              <span>{localeTaskDescription[currentLocale].summary.taskTypeImage}</span>
            )}
            {data.taskType === 'device' && (
              <span>{localeTaskDescription[currentLocale].summary.taskTypeDevice}</span>
            )}
            {data.taskType === 'asset_synchronize' && (
              <span>{localeTaskDescription[currentLocale].summary.assetSynchronize}</span>
            )}
          </td>
        </tr>
        <tr className="actions">
          <td>
            <span>{localeTaskDescription[currentLocale].summary.actions}</span>
          </td>
          <td className="task__actions">
            {data.actions &&
              (data.taskType === 'host' || data.taskType === 'asset') &&
              data.actions.audit && <TaskType title type="audit" />}
            {data.actions &&
              (data.taskType === 'host' || data.taskType === 'asset') &&
              data.actions.inventorization && <TaskType title type="inventorization" />}
            {data.actions &&
              (data.taskType === 'host' || data.taskType === 'asset') &&
              data.actions.agent_update && <TaskType title type="agent_update" />}
            {data.actions &&
              (data.taskType === 'host' || data.taskType === 'asset') &&
              data.actions.package_update && <TaskType title type="package_update" />}
            {data.actions &&
              (data.taskType === 'host' || data.taskType === 'asset') &&
              data.actions.windows_software_management && (
                <TaskType title type="windows_software_management" />
              )}
            {data.actions && data.taskType === 'report' && data.actions.group_report && (
              <TaskType title type="group_report" />
            )}
            {data.actions && data.taskType === 'report' && data.actions.single_report && (
              <TaskType title type="single_report" />
            )}
            {data.actions && data.taskType === 'report' && data.actions.notification?.email && (
              <TaskType title type="notification" />
            )}
            {data.actions &&
              (data?.taskType === 'image' || data?.taskType === 'asset') &&
              data.actions.audit_image && <TaskType title type="audit_image" />}
            {data.actions && data?.taskType === 'device' && data.actions.audit && (
              <TaskType title type="audit" />
            )}
            {data.actions && data?.taskType === 'device' && data.actions.inventorization && (
              <TaskType title type="inventorization" />
            )}
            {data.actions &&
              (data.taskType === 'host' || data.taskType === 'asset') &&
              data.actions.kb_install && <TaskType title type="kb_install" />}
            {data.actions &&
              data.taskType === 'asset_synchronize' &&
              data.actions.asset_ldap_synchronize && (
                <TaskType title type="asset_ldap_synchronize" />
              )}
            {data.actions &&
              (data.taskType === 'host' || data.taskType === 'asset') &&
              data.actions.system_reboot && <TaskType title type="system_reboot" />}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
