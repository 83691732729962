/* eslint-disable no-nested-ternary */
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from './WrapperMenu';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function Memory({ data }: { data: InventorizationResponse }): React.ReactElement {
  return (
    <WrapperMenu title={locale[currentLocale].memory.title}>
      <WrapperMenu title={locale[currentLocale].memory.titleInfo}>
        <table className="column">
          <tbody>
            <tr>
              <td>
                <span>{locale[currentLocale].memory.total}</span>
              </td>
              <td>
                {(data.type === 'linux' || data.type === 'windows') &&
                data.result.memory?.info?.total != null ? (
                  <span>
                    {Math.round(Number(data.result.memory.info.total) / 1024 / 1024)}{' '}
                    {locale[currentLocale].sizeMb}
                  </span>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <span>{locale[currentLocale].memory.free}</span>
              </td>
              <td>
                {(data.type === 'linux' || data.type === 'windows') &&
                data.result.memory?.info?.free != null ? (
                  <span>
                    {Math.round(Number(data.result.memory.info.free) / 1024 / 1024)}{' '}
                    {locale[currentLocale].sizeMb}
                  </span>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <span>{locale[currentLocale].memory.buffers}</span>
              </td>
              <td>
                {data.type === 'linux' &&
                data.result.memory?.info &&
                'buffers' in data.result.memory.info &&
                data.result.memory?.info.buffers != null ? (
                  <span>
                    {Math.round(Number(data.result.memory?.info.buffers) / 1024 / 1024)}
                    {locale[currentLocale].sizeMb}
                  </span>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="column">
          <tbody>
            {data.type === 'linux' &&
              data.result.memory?.info &&
              'cached' in data.result.memory.info && (
                <tr>
                  <td>
                    <span>{locale[currentLocale].memory.cached}</span>
                  </td>
                  <td>
                    {data.result.memory?.info.cached ? (
                      <span>
                        {Math.round(Number(data.result.memory.info.cached) / 1024 / 1024)}{' '}
                        {locale[currentLocale].sizeMb}
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                </tr>
              )}
            {(data.type === 'linux' || data.type === 'windows') &&
              data.result.memory?.info &&
              'active' in data.result.memory.info && (
                <tr>
                  <td>
                    <span>{locale[currentLocale].memory.active}</span>
                  </td>
                  <td>
                    {data.result.memory.info.active != null ? (
                      <span>
                        {Math.round(Number(data.result.memory.info.active) / 1024 / 1024)}{' '}
                        {locale[currentLocale].sizeMb}
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                </tr>
              )}
            <tr>
              {data.type === 'linux' &&
                data.result.memory?.info &&
                'inactive' in data.result.memory.info && (
                  <>
                    <td>
                      <span>{locale[currentLocale].memory.inactive}</span>
                    </td>
                    <td>
                      {data.result.memory.info.inactive != null ? (
                        <span>
                          {Math.round(Number(data.result.memory.info.inactive) / 1024 / 1024)}{' '}
                          {locale[currentLocale].sizeMb}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                  </>
                )}
            </tr>
          </tbody>
        </table>
        <table className="column">
          <tbody>
            <tr>
              <td>
                <span>{locale[currentLocale].memory.swapTotal}</span>
              </td>
              <td>
                <span>
                  {(data.type === 'linux' || data.type === 'windows') &&
                    Math.round(Number(data.result?.memory?.info?.swap?.total) / 1024 / 1024)}{' '}
                  {locale[currentLocale].sizeMb}
                </span>
              </td>
            </tr>
            {data.type === 'linux' &&
              data.result.memory?.info &&
              data.result.memory.info.swap &&
              'cached' in data.result.memory.info.swap && (
                <tr>
                  <td>
                    <span>{locale[currentLocale].memory.swapCached}</span>
                  </td>
                  <td>
                    {data.result.memory.info.swap.cached != null ? (
                      <span>
                        {Math.round(Number(data.result.memory.info.swap.cached) / 1024 / 1024)}{' '}
                        {locale[currentLocale].sizeMb}
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                </tr>
              )}
            {data.type === 'linux' &&
              data.result.memory?.info &&
              data.result.memory.info.swap &&
              'free' in data.result.memory.info.swap && (
                <tr>
                  <td>
                    <span>{locale[currentLocale].memory.swapFree}</span>
                  </td>
                  <td>
                    {data.result.memory.info.swap.free != null ? (
                      <span>
                        {Math.round(Number(data.result.memory.info.swap.free) / 1024 / 1024)}{' '}
                        {locale[currentLocale].sizeMb}
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                </tr>
              )}
          </tbody>
        </table>

        {/* <MemoryTableInfo data={data} memoryInfo={memoryInfo} /> */}
      </WrapperMenu>
      <WrapperMenu title={locale[currentLocale].devices.title}>
        {(data.type === 'linux' || data.type === 'windows') &&
          data.result?.memory?.devices?.map((d) => (
            <WrapperMenu title={d?.device_locator}>
              <table className="column">
                <tbody>
                  <tr>
                    <td>
                      <span>{locale[currentLocale].devices.size}</span>
                    </td>
                    <td>
                      {d && 'size' in d && d.size ? (
                        <span>
                          {d.size} {locale[currentLocale].sizeMb}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{locale[currentLocale].devices.formFactor}</span>
                    </td>
                    <td>{d && 'form_factor' in d && <span>{d.form_factor}</span>}</td>
                  </tr>
                  <tr>
                    <td>
                      <span>{locale[currentLocale].devices.memoryType}</span>
                    </td>
                    <td>{d && 'memory_type' in d && <span>{d.memory_type}</span>}</td>
                  </tr>
                  <tr>
                    <td>
                      <span>{locale[currentLocale].devices.bankLocator}</span>
                    </td>
                    <td>{d && 'bank_locator' in d && <span>{d.bank_locator}</span>}</td>
                  </tr>
                </tbody>
              </table>
              <table className="column">
                <tbody>
                  <tr>
                    <td>
                      <span>{locale[currentLocale].devices.manufacturer}</span>
                    </td>
                    <td>{d && 'manufacturer' in d && <span>{d.manufacturer}</span>}</td>
                  </tr>
                  <tr>
                    <td>
                      <span>{locale[currentLocale].devices.part_number}</span>
                    </td>
                    <td>{d && 'part_number' in d && <span>{d.part_number}</span>}</td>
                  </tr>
                  <tr>
                    <td>
                      <span>{locale[currentLocale].devices.serialNumber}</span>
                    </td>
                    <td>{d && 'serial_number' in d && <span>{d.serial_number}</span>}</td>
                  </tr>
                  <tr>
                    <td>
                      <span>{locale[currentLocale].devices.assetTag}</span>
                    </td>
                    <td>{d && 'asset_tag' in d && <span>{d.asset_tag}</span>}</td>
                  </tr>
                </tbody>
              </table>
              <table className="column">
                <tbody>
                  <tr>
                    <td>
                      <span>{locale[currentLocale].devices.dataWidth}</span>
                    </td>
                    <td>
                      {d && 'data_width' in d && d.data_width ? (
                        <span>{d.data_width}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{locale[currentLocale].devices.totalWidth}</span>
                    </td>
                    <td>
                      {d && 'total_width' in d && d.total_width ? (
                        <span>{d.total_width}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{locale[currentLocale].devices.maxSpeed}</span>
                    </td>
                    <td>
                      {d && 'max_speed' in d && d.max_speed ? (
                        <span>{d.max_speed}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{locale[currentLocale].devices.configuredClockSpeed}</span>
                    </td>
                    <td>
                      {d && 'configured_clock_speed' in d && d.configured_clock_speed ? (
                        <span>{d.configured_clock_speed}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="column">
                <tbody>
                  <tr>
                    {data.type === 'linux' && (
                      <>
                        <td>
                          <span>{locale[currentLocale].devices.handle}</span>
                        </td>
                        <td>
                          {d && 'handle' in d && typeof d.handle === 'string' && (
                            <span>{d.handle}</span>
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    {data.type === 'linux' && (
                      <>
                        <td>
                          <span>{locale[currentLocale].devices.handleArray}</span>
                        </td>
                        <td>
                          {d && 'array_handle' in d && typeof d.array_handle === 'string' && (
                            <span>{d.array_handle}</span>
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>
                      <span>{locale[currentLocale].devices.memoryTypeDetails}</span>
                    </td>
                    <td>
                      {d && 'memory_type_details' in d && <span>{d.memory_type_details}</span>}
                    </td>
                  </tr>
                </tbody>
              </table>
            </WrapperMenu>
          ))}
        {/* <MemoryTableDevices
          data={data}
          memoryDevCls={memoryDevCls}
          devicestable={devicestable}
          handleRowDisk={handleRowDisk}
        /> */}
      </WrapperMenu>
    </WrapperMenu>
  );
}
