/* eslint-disable no-nested-ternary */
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { WrapperMenu } from './WrapperMenu';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function Os({ data }: { data: InventorizationResponse }): React.ReactElement {
  return (
    <WrapperMenu title={locale[currentLocale].os.title}>
      <table className="column">
        <tbody>
          <tr>
            <td>
              <span>{locale[currentLocale].os.name}</span>
            </td>
            <td>
              <span>{data.result.os?.name}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>{locale[currentLocale].os.version}</span>
            </td>
            <td>
              <span>{data.result.os?.version}</span>
            </td>
          </tr>
          {(data.type === 'hypervisor' ||
            (data.type === 'firewall' && data.class === 'checkpoint')) && (
            <tr>
              <td>
                <span>{locale[currentLocale].os.build}</span>
              </td>
              <td>
                <span>{data.result.os?.build}</span>
              </td>
            </tr>
          )}
          <tr>
            <td>
              <span>{locale[currentLocale].os.type}</span>
            </td>
            <td>
              <span>
                {data.result.os?.type[0].toUpperCase()}
                {data.result.os?.type.slice(1)}
              </span>
            </td>
          </tr>
          {data.class === 'checkpoint' && data.result.os.take && (
            <tr>
              <td>
                <span>Take</span>
              </td>
              <td>{data.result.os.take}</td>
            </tr>
          )}
          {data.type !== 'router' && data.type !== 'firewall' && data.type !== 'hypervisor' && (
            <tr>
              <td>
                <span>{locale[currentLocale].os.arch}</span>
              </td>
              <td>
                {(data.type === 'linux' || data.type === 'windows') && data.result.os?.arch ? (
                  <span>{data.result.os?.arch}</span>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <table className="column">
        <tbody>
          {data.type !== 'router' && data.type !== 'firewall' && data.type !== 'hypervisor' && (
            <tr>
              <td>
                <span>{locale[currentLocale].os.platform}</span>
              </td>
              <td>
                {(data.type === 'linux' || data.type === 'windows') && data.result.os?.platform ? (
                  <span>
                    {data.result.os?.platform[0].toUpperCase()}
                    {data.result.os?.platform.slice(1)}
                  </span>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
          )}
          {data.type === 'hypervisor' && (
            <tr>
              <td>
                <span>{locale[currentLocale].os.path}</span>
              </td>
              <td>
                <span>
                  {data.result.os?.patch || data.result.os?.patch === 0
                    ? data.result.os?.patch
                    : '-'}
                </span>
              </td>
            </tr>
          )}
          {data.type !== 'router' && data.type !== 'firewall' && data.type !== 'hypervisor' && (
            <tr>
              {' '}
              <td>
                <span>{locale[currentLocale].os.platformLike}</span>
              </td>
              <td>
                {(data.type === 'linux' || data.type === 'windows') &&
                data.result.os?.platform_like ? (
                  <span>
                    {data.result.os?.platform_like[0].toUpperCase()}
                    {data.result.os?.platform_like?.slice(1)}
                  </span>
                ) : (
                  <span>-</span>
                )}
              </td>{' '}
            </tr>
          )}
          {data.type === 'hypervisor' && (
            <tr>
              <td>
                <span>{locale[currentLocale].os.update}</span>
              </td>
              <td>
                {data.result.os.update || data.result.os.update === 0 ? (
                  <span>{data.result.os.update}</span>
                ) : (
                  <span>-</span>
                )}
              </td>{' '}
            </tr>
          )}
          {data.type !== 'router' && data.type !== 'firewall' && (
            <tr>
              {' '}
              <td>
                <span>{locale[currentLocale].os.installDate}</span>
              </td>
              <td>
                {data.result.os?.install_date ? (
                  <span>{new Date(String(data.result.os?.install_date)).toLocaleString()}</span>
                ) : (
                  <span>-</span>
                )}
              </td>
              <td />{' '}
            </tr>
          )}
        </tbody>
      </table>
    </WrapperMenu>
  );
}
