import { Grid, GridColumn } from '@progress/kendo-react-grid';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import { WrapperMenu } from './WrapperMenu';
import { StandartCell } from '../../../../common/cells/StandartCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function Containers({ data }: { data: InventorizationResponse }): React.ReactElement {
  return (
    <WrapperMenu title={locale[currentLocale].containers.title}>
      {data.type === 'linux' &&
        data.result.containers?.map((container) => (
          <WrapperMenu title={container.name || container.id}>
            <table className="column">
              <tbody>
                <tr>
                  <td>{locale[currentLocale].containers.id}</td>
                  <td>{container.id}</td>
                </tr>
                <tr>
                  <td>{locale[currentLocale].containers.image}</td>
                  <td>{container.image || ''}</td>
                </tr>
              </tbody>
            </table>
            <table className="column" />
            <div className="break" />
            <table className="column">
              <tbody>
                <tr>
                  <td>{locale[currentLocale].containers.startedAt}</td>
                  <td>
                    {container.state?.startedAt
                      ? new Date(container.state?.startedAt).toLocaleString()
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td>{locale[currentLocale].containers.status}</td>
                  <td>{container.state?.status || '-'}</td>
                </tr>
                <tr>
                  <td>{locale[currentLocale].containers.exitCode}</td>
                  <td>
                    {container.state?.exitCode || (container.state?.exitCode === 0 ? '0' : '-')}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="column">
              <tbody>
                <tr>
                  <td>{locale[currentLocale].containers.finishedAt}</td>
                  <td>{container.state?.finishedAt || '-'}</td>
                </tr>
                <tr>
                  <td>{locale[currentLocale].containers.cmd}</td>
                  <td>{container.cmd?.join(' ') || '-'}</td>
                </tr>
                {container.state?.error && (
                  <tr>
                    <td>{locale[currentLocale].containers.error}</td>
                    <td>{container.state?.error}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <table className="column">
              <tbody>
                <tr>
                  <td>{locale[currentLocale].containers.pid}</td>
                  <td>{container.state?.pid || '-'}</td>
                </tr>
              </tbody>
            </table>
            <div className="break" />
            <div>
              <WrapperMenu title={locale[currentLocale].containers.volumes}>
                <Grid data={container.fileMounts} scrollable="none" className="no-margin-top">
                  <GridColumn
                    field="source"
                    title={locale[currentLocale].containers.source}
                    cell={StandartCell}
                    className="title"
                  />
                  <GridColumn
                    field="destination"
                    title={locale[currentLocale].containers.destination}
                    cell={StandartCell}
                    className="title"
                  />
                  <GridColumn
                    field="type"
                    title={locale[currentLocale].containers.type}
                    cell={StandartCell}
                  />
                  <GridColumn
                    field="mode"
                    title={locale[currentLocale].containers.mode}
                    cell={StandartCell}
                  />
                </Grid>
              </WrapperMenu>

              {container.portBindings && (
                <>
                  <div className="break" />
                  <WrapperMenu title={locale[currentLocale].containers.ports}>
                    <Grid data={container.portBindings} scrollable="none" className="no-margin-top">
                      <GridColumn
                        field="hostIp"
                        title={locale[currentLocale].containers.ip}
                        cell={StandartCell}
                      />
                      <GridColumn
                        field="hostPort"
                        title={locale[currentLocale].containers.port}
                        cell={StandartCell}
                      />
                      <GridColumn
                        field="containerPort"
                        title={locale[currentLocale].containers.containerPort}
                        cell={StandartCell}
                      />
                    </Grid>
                  </WrapperMenu>
                </>
              )}
            </div>
          </WrapperMenu>
        ))}
    </WrapperMenu>
  );
}
