import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ReactElement } from 'react';
import { TaskType } from '../../../Task/common/TaskType';
import styles from '../MaintenanceWindows.module.scss';

export function MaintenanceActionsCell(props: GridCellProps): ReactElement {
  const { dataItem } = props;

  const actions: (
    | 'audit'
    | 'inventorization'
    | 'agent_update'
    | 'audit_image'
    | 'package_update'
    | 'group_report'
    | 'single_report'
    | 'bulletin_specific_analysis'
    | 'notification'
    | 'kb_install'
    | 'asset_ldap_synchronize'
    | 'system_reboot'
  )[] = [];
  if (dataItem.taskActions.audit) actions.push('audit');
  if (dataItem.taskActions.inventorization) actions.push('inventorization');
  if (dataItem.taskActions.agent_update) actions.push('agent_update');
  if (dataItem.taskActions.package_update) actions.push('package_update');
  if (dataItem.taskActions.kb_install) actions.push('kb_install');
  if (dataItem.taskActions.system_reboot) actions.push('system_reboot');

  return (
    <td
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
      }}
      className={dataItem?.period?.isDisabled ? styles.isDisabledActions : ''}
    >
      {actions.map((c) => (
        <TaskType title type={c} />
      ))}
    </td>
  );
}
